import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from "axios";
import { getCookie } from "cookies-next";
import { AccountDetails } from "pages/organizations/settings/[...integrations]";
import posthog from "posthog-js";
import queryString from "query-string";
import { Analytics } from "types/analytics";
import {
  BaseOrganization,
  BaseUser,
  ConnectorJobPost,
  JobApplication,
  JobApplicationVariant,
  JobPost,
  Network,
  NetworkMember,
  Organization,
  OrganizationMembership,
  OrganizationMembershipRole,
  PublicUser,
  SessionUser,
  User,
  ZeldaUser,
} from "types/models";
import { BaseConnectorPreference } from "types/models/connectorPreferenceBase";
import { ContractEntry } from "types/models/contractEntry";
import { BaseDocument } from "types/models/documentBase";
import { BaseEmployerOnboarding } from "types/models/employerOnboardingBase";
import { BaseGeneratedJobDescription } from "types/models/generatedJobDescriptionBase";
import { BaseInvitation } from "types/models/invitationBase";
import { LedgerEntry } from "types/models/ledgerEntry";
import { BaseNotificationPreference } from "types/models/notificationPreferenceBase";
import { PromptVariant } from "types/models/promptBase";
import { ConnectorJobPostsSSG, JobPostsSSG, OrganizationsSSG, UsersSSG } from "types/models/ssgList";
import { Pagination, ServiceResponse } from "types/service";
import { UserRole } from "types/userRole";

import { AUTH_REFRESH_TOKEN_COOKIE } from "@/lib/constants";
import { parseApiError } from "@/lib/errors";

type CustomHeaders = {
  "ssg-list-access-key"?: string;
};

export default class ZettlorService {
  baseUrl: string;

  constructor() {
    this.baseUrl = (typeof Window === "undefined" ? process.env.NEXT_PUBLIC_ZETTLOR_API_URL : "") + "/api/v1";
  }

  private async _getRequestConfig(headers: Partial<RawAxiosRequestHeaders> & CustomHeaders = {}, params: any = {}) {
    const baseHeaders: Partial<RawAxiosRequestHeaders> = {
      "Content-Type": "application/json",
    };

    try {
      baseHeaders["anon-distinct-id"] = posthog.get_distinct_id();
    } catch {}

    return {
      ...params,
      headers: {
        ...baseHeaders,
        ...headers,
      },
    };
  }

  private async _makeRequest(config: Partial<AxiosRequestConfig>): Promise<AxiosResponse> {
    const initialConfig = { ...config };
    try {
      // Proxy /api requests on client, use full URL on server
      return await axios.request({
        baseURL: this.baseUrl,
        ...config,
        ...(await this._getRequestConfig(config.headers, config.params)),
      });
    } catch (error) {
      const err = error as AxiosError;
      if (err.response && err.response.status === 401) {
        return this._refreshSession(initialConfig);
      } else {
        throw error; // Handle the initial request error accordingly
      }
    }
  }

  private async _refreshSession(config: Partial<AxiosRequestConfig>): Promise<AxiosResponse> {
    try {
      const refreshToken = getCookie(AUTH_REFRESH_TOKEN_COOKIE);
      // Make the refresh request - this will set the cookie
      await axios.request({
        method: "POST",
        baseURL: this.baseUrl,
        url: "/auth/token/refresh",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          refresh: refreshToken,
        },
      });
      // Retry the initial request with the updated headers
      return this._makeRequest(config);
    } catch (refreshError) {
      throw refreshError; // Handle the refresh error accordingly
    }
  }

  // ==================================
  //            JOB POSTS
  // ==================================

  async listJobPostsSSG({ limit, page }: { limit?: number; page?: number } = {}): Promise<
    ServiceResponse<Pagination<JobPostsSSG[]>>
  > {
    try {
      const query = queryString.stringify({
        limit: limit || undefined,
        page: page || undefined,
      });
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/jobs/ssg-list/?${query}`,
        headers: {
          "ssg-list-access-key": process.env.SSG_LIST_ACCESS_KEY,
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async searchJobPosts(params: {
    page: number;
    search?: string;
    industry?: string[];
    remote?: boolean;
    organization?: string;
    min_value?: string;
    max_value?: string;
    sort_by?: string;
    location?: {
      city?: string;
      state?: string;
      country?: string;
      latitude?: number;
      longitude?: number;
    };
    jobLocationType?: string;
    benefits?: string[];
    compensationPeriod?: string;
    compensationRange?: string;
    compensationMin?: number;
    compensationMax?: number;
  }): Promise<ServiceResponse<Pagination<JobPost[]>>> {
    try {
      // Check if the search parameter includes specific keywords
      if (params.search) {
        const searchTerms = params.search.toLowerCase().split(" ");
        if (searchTerms.includes("remote")) {
          params.remote = true;
        }
        if (searchTerms.some((term) => ["full-time", "part-time", "contract"].includes(term))) {
          params.jobLocationType = searchTerms.find((term) => ["full-time", "part-time", "contract"].includes(term));
        }
        const compensationKeywords = searchTerms.filter((term) => /^\$?\d+/.test(term));
        if (compensationKeywords.length > 0) {
          const [min, max] = compensationKeywords.map((keyword) => parseInt(keyword.replace(/^\$/, "")));
          params.compensationMin = min;
          params.compensationMax = max;
        }
      }

      const { data } = await this._makeRequest({
        method: "GET",
        url: `/jobs/search`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }
  async getJobPost(jobPostUuid: string): Promise<ServiceResponse<JobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/jobs/${jobPostUuid}/`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getJobPostAnalytics(jobPostUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/jobs/${jobPostUuid}/analytics`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listJobPosts(params: {
    page: number;
    organization?: string;
    search?: string;
    statuses?: string;
  }): Promise<ServiceResponse<Pagination<JobPost[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/jobs`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateJobPost(jobPostUuid: string, body: any): Promise<ServiceResponse<JobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/jobs/${jobPostUuid}`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createJobPost(body: any): Promise<ServiceResponse<JobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/jobs`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async duplicateJobPost(jobPostUuid: string): Promise<ServiceResponse<JobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/jobs/${jobPostUuid}/duplicate`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async archiveJobPost(jobPostUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/jobs/${jobPostUuid}/archive`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async generateJobDescription(body: any): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/ai-experiments/generate-job-description`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getGeneratedJobDescription(
    generatedJobDescriptionUuid: string,
  ): Promise<ServiceResponse<BaseGeneratedJobDescription>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/ai-experiments/generate-job-description/${generatedJobDescriptionUuid}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async setJobToPublicIfOrganizationHasActiveSubscription(jobUuid: string): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/jobs/${jobUuid}/set-public-if-organization-has-active-subscription`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //       CONNECTOR JOB POSTS
  // ==================================

  async listConnectorJobPostsSSG({ limit, page }: { limit?: number; page?: number } = {}): Promise<
    ServiceResponse<Pagination<ConnectorJobPostsSSG[]>>
  > {
    try {
      const query = queryString.stringify({
        limit: limit || undefined,
        page: page || undefined,
      });
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-job-posts/ssg-list/?${query}`,
        headers: {
          "ssg-list-access-key": process.env.SSG_LIST_ACCESS_KEY,
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async searchConnectorJobPosts(params: {
    connector: string;
    page: number;
    search?: string;
    industry?: string[];
    remote?: boolean;
  }): Promise<ServiceResponse<Pagination<ConnectorJobPost[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: "/connector-job-posts/search",
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listConnectorJobPosts(params: {
    page: number;
    connector_organization: string;
    search?: string;
    statuses?: string;
  }): Promise<ServiceResponse<Pagination<ConnectorJobPost[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-job-posts`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async connectorJobPostLookup(jobPostUuid: string): Promise<ServiceResponse<ConnectorJobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-job-posts/lookup`,
        params: {
          job_post: jobPostUuid,
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getConnectorJobPost(connectorJobPostUuid: string): Promise<ServiceResponse<ConnectorJobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-job-posts/${connectorJobPostUuid}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getSourcedConnectorJobPost(jobPostUuid: string): Promise<ServiceResponse<ConnectorJobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/jobs/${jobPostUuid}/sourced-connector-job-post`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getConnectorJobPostAnalytics(connectorJobPostUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-job-posts/${connectorJobPostUuid}/analytics`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateConnectorJobPost(connectorJobPostUuid: string, body: any): Promise<ServiceResponse<ConnectorJobPost>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/connector-job-posts/${connectorJobPostUuid}`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createConnectorJobPost(body: any): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/connector-job-posts`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async archiveConnectorJobPost(connectorJobPostUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/connector-job-posts/${connectorJobPostUuid}/archive`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //           CONTRACTS
  // ==================================

  async createContractOffer(contractUuid: string, body: any): Promise<ServiceResponse<ContractEntry>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/contracts/${contractUuid}/offer`,
        data: body,
      });
      return {
        data: data as ContractEntry,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //         JOB APPLICATIONS
  // ==================================

  async getJobApplication(jobApplicationUuid: any): Promise<ServiceResponse<JobApplication>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/job-applications/${jobApplicationUuid}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listUserJobApplications(
    userUuid: string,
    params: {
      page: number;
      connector_job_post?: string;
    },
  ): Promise<ServiceResponse<Pagination<JobApplication[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/${userUuid}/job-applications`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listConnectorOrganizationsJobApplications(
    connectorOrganizationUuid: string,
    params: {
      page: number;
      connector_job_post?: string;
      organizations?: string;
      network_member?: string;
      search?: string;
      statuses?: string;
    },
  ): Promise<ServiceResponse<Pagination<JobApplication[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}/job-applications`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listOrganizationsJobApplications(
    organizationUuid: string,
    params: {
      page: number;
      search?: string;
      statuses?: string;
      connectors?: string;
    },
  ): Promise<ServiceResponse<Pagination<JobApplication[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/job-applications`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listOrganizationsJobApplicationsConnectors(
    organizationUuid: string,
    params: {
      search?: string;
      page: number;
    },
  ): Promise<ServiceResponse<Pagination<BaseUser[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/job-applications/connectors`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listConnectorOrganizationsJobApplicationsOrganizations(
    connectorOrganizationUuid: string,
    params: {
      search?: string;
      page: number;
    },
  ): Promise<ServiceResponse<Pagination<BaseOrganization[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}/job-applications/organizations`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async acceptJobApplication(jobApplicationUuid: any): Promise<ServiceResponse<JobApplication>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/job-applications/${jobApplicationUuid}/accept`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async rejectJobApplication(
    jobApplicationUuid: any,
    body: { reject_reason: string; reject_feedback?: string },
  ): Promise<ServiceResponse<JobApplication>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/job-applications/${jobApplicationUuid}/reject`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createJobApplicationForm(connectorJobPostUuid: string, body: any): Promise<ServiceResponse<JobApplication>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/connector-job-posts/${connectorJobPostUuid}/job-applications/apply`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createJobApplicationUnAuthenticatedForm(
    connectorJobPostUuid: string,
    body: any,
  ): Promise<ServiceResponse<JobApplication>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/connector-job-posts/${connectorJobPostUuid}/job-applications/apply/unauthenticated`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createJobApplicationSubmit(
    connectorJobPostUuid: string,
    body: {
      network_member?: string;
      email?: string;
      variant?: JobApplicationVariant;
    },
  ): Promise<ServiceResponse<JobApplication>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/connector-job-posts/${connectorJobPostUuid}/job-applications/submit`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async archiveJobApplication(jobApplicationUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/job-applications/${jobApplicationUuid}/archive`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async hiredJobApplication(jobApplicationUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/job-applications/${jobApplicationUuid}/hired`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async withdrawJobApplication(jobApplicationUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/job-applications/${jobApplicationUuid}/withdraw`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async markJobApplicationRead(jobApplicationUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/job-applications/${jobApplicationUuid}/mark-read`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //          ORGANIZATIONS
  // ==================================

  async listOrganizationsSSG({ limit, page }: { limit?: number; page?: number } = {}): Promise<
    ServiceResponse<Pagination<OrganizationsSSG[]>>
  > {
    try {
      const query = queryString.stringify({
        limit: limit || undefined,
        page: page || undefined,
      });
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/ssg-list/?${query}`,
        headers: {
          "ssg-list-access-key": process.env.SSG_LIST_ACCESS_KEY,
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getOrganization(organizationUuid: string): Promise<ServiceResponse<Organization>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getOrganizationByHandle(handle: string): Promise<ServiceResponse<Organization>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/handle/${handle}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createOrganizationStripePortalSession(
    organizationUuid: string,
    body: {
      return_url?: string;
    },
  ): Promise<ServiceResponse<{ portal_session_url: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/billing/stripe-portal-session`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createOrganizationStripeCheckoutSession(
    organizationUuid: string,
    body: {
      success_url?: string;
      cancel_url?: string;
      metadata?: any;
    },
  ): Promise<ServiceResponse<{ checkout_session_url: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/billing/stripe-checkout-session`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createOrganizationEmployerPlanStripeCheckoutSession(
    organizationUuid: string,
    body: {
      price_id: string;
      success_url?: string;
      cancel_url?: string;
      metadata?: any;
    },
  ): Promise<ServiceResponse<{ checkout_session_url: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/billing/employer-plan-stripe-checkout-session`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createOrganizationStripeSetupIntent(
    organizationUuid: string,
  ): Promise<ServiceResponse<{ client_secret: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/billing/stripe-setup-intent`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createOrganizationForm(body: FormData): Promise<ServiceResponse<Organization>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateOrganizationForm(organizationUuid: string, body: FormData): Promise<ServiceResponse<Organization>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/organizations/${organizationUuid}`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getOrganizationMergeLinkToken(
    organizationUuid: string,
    category: string,
  ): Promise<ServiceResponse<{ link_token: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/merge/link-token/${category}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async swapOrganizationMergePublicToken(
    organizationUuid: string,
    category: string,
    publicToken: string,
  ): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/merge/swap-public-token/${category}`,
        data: {
          public_token: publicToken,
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getOrganizationMergeAccountDetails(
    organizationUuid: string,
    category: string,
  ): Promise<ServiceResponse<AccountDetails>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/merge/account-details/${category}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getOrganizationWallet(organizationUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/wallet`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //      ORGANIZATION MEMBERSHIP
  // ==================================

  async listOrganizationMembers(
    organizationUuid: string,
    params: {
      page: number;
    },
  ): Promise<ServiceResponse<Pagination<Partial<OrganizationMembership>[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/members`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async removeOrganizationMember(organizationUuid: string, userUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/members/${userUuid}/remove`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //      CONNECTOR ORGANIZATIONS
  // ==================================

  async getConnectorOrganization(connectorOrganizationUuid: string): Promise<ServiceResponse<Organization>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getConnectorOrganizationWallet(connectorOrganizationUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}/wallet`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createConnectorOrganizationStripeConnectLink(
    connectorOrganizationUuid: string,
  ): Promise<ServiceResponse<{ link_url: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}/stripe-connect-link`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createConnectorOrganizationStripePortalSession(
    connectorOrganizationUuid: string,
    body: {
      return_url?: string;
    },
  ): Promise<ServiceResponse<{ portal_session_url: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/connector-organizations/${connectorOrganizationUuid}/billing/stripe-portal-session`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createConnectorOrganizationStripeCheckoutSession(
    connectorOrganizationUuid: string,
    body: {
      price_id: string;
      success_url?: string;
      cancel_url?: string;
      metadata?: any;
    },
  ): Promise<ServiceResponse<{ checkout_session_url: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/connector-organizations/${connectorOrganizationUuid}/billing/stripe-checkout-session`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createConnectorOrganizationStripeSetupIntent(
    connectorOrganizationUuid: string,
  ): Promise<ServiceResponse<{ client_secret: string }>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}/billing/stripe-setup-intent`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //          INVITATIONS
  // ==================================

  async createInvitations(
    organizationUuid: string,
    body: {
      invitations: {
        email: string;
        first_name: string;
        last_name: string;
        phone_number?: string;
        role: OrganizationMembershipRole;
      }[];
    },
  ): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/invitations`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async importInvitationsPreview(organizationUuid: string, body: FormData): Promise<ServiceResponse<BaseInvitation[]>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/invitations/import/preview`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async importInvitations(organizationUuid: string, body: FormData): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/invitations/import`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async syncInvitations(organizationUuid: string): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/organizations/${organizationUuid}/invitations/sync`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listInvitations(
    organizationUuid: string,
    params: {
      page: number;
      statuses: string;
    },
  ): Promise<ServiceResponse<Pagination<BaseInvitation[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/organizations/${organizationUuid}/invitations`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async cancelInvitation(invitationUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/invitations/${invitationUuid}/cancel`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //              USERS
  // ==================================

  async getSessionUser(): Promise<ServiceResponse<SessionUser>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/api/auth/user`, // Updated to use core auth path
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listUsersSSG({ limit, page }: { limit?: number; page?: number } = {}): Promise<
    ServiceResponse<Pagination<UsersSSG[]>>
  > {
    try {
      const query = queryString.stringify({
        limit: limit || undefined,
        page: page || undefined,
      });
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/ssg-list/?${query}`,
        headers: {
          "ssg-list-access-key": process.env.SSG_LIST_ACCESS_KEY,
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getUser(userUuid: string): Promise<ServiceResponse<User>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/${userUuid}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getPublicUser(userUuid: string): Promise<ServiceResponse<PublicUser>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/${userUuid}/public`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getUserByHandle(handle: string): Promise<ServiceResponse<User>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/handle/${handle}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateUser(userUuid: string, user: any): Promise<ServiceResponse<User>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/users/${userUuid}`,
        data: user,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateUserForm(userUuid: string, body: FormData): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/users/${userUuid}`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateUserNotificationPreferences(
    userUuid: string,
    body: Partial<BaseNotificationPreference>,
  ): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/users/${userUuid}/notification-preferences`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateUserRole(userUuid: string, role: string): Promise<ServiceResponse<User>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/users/${userUuid}/role`,
        data: {
          role,
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async changePassword(body: {
    old_password: string;
    new_password1: string;
    new_password2: string;
  }): Promise<ServiceResponse<User>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/auth/password/change`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async checkIfUserIsScreeningEligible(userUuid: string): Promise<ServiceResponse<boolean>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/${userUuid}/screening-eligibility`,
      });
      return {
        data: data.is_eligible,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getUserConnectorJobPostRecommendations(
    userUuid: string,
    params: { limit?: number; page: number },
  ): Promise<ServiceResponse<Pagination<ConnectorJobPost[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/${userUuid}/connector-job-post-recommendations`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //              PREFERENCES
  // ==================================

  async createOrUpdateConnectorPreference(
    userUuid: string,
    body: any,
  ): Promise<ServiceResponse<BaseConnectorPreference>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/users/${userUuid}/connector-preferences`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createOrUpdateWorkerPreference(userUuid: string, body: any): Promise<ServiceResponse<BaseConnectorPreference>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/users/${userUuid}/worker-preferences`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //              HANDLES
  // ==================================

  async checkHandle(handle: string): Promise<ServiceResponse<boolean>> {
    try {
      const { status } = await this._makeRequest({
        method: "GET",
        url: `/handles/check-handle/${handle}`,
      });
      return {
        data: status === 204,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getHandleByName(handle: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/handles/h/${handle}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createHandle(body: { handle: string }): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/handles`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateHandle(uuid: string, body: { handle: string }): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/handles/${uuid}`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //         PHONE VERIFICATION
  // ==================================

  async startPhoneVerification(body: { phone_number: string }): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/phone-verification/start`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async checkPhoneVerification(body: { phone_number: string; code: string }): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/phone-verification/check`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //       NETWORK MEMBERS
  // ==================================

  async getOrCreateNetwork(connectorOrganizationUuid: string): Promise<ServiceResponse<Network>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}/network`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async createNetworkMemberForm(body: FormData): Promise<ServiceResponse<NetworkMember>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/network-members`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getNetworkMember(networkMemberUuid: string): Promise<ServiceResponse<NetworkMember>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/network-members/${networkMemberUuid}`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async updateNetworkMemberForm(networkMemberUuid: string, body: FormData): Promise<ServiceResponse<NetworkMember>> {
    try {
      const { data } = await this._makeRequest({
        method: "PATCH",
        url: `/network-members/${networkMemberUuid}`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listNetworkMembers(
    networkUuid: string,
    params: {
      page: number;
      search?: string;
      statuses?: string;
    },
  ): Promise<ServiceResponse<Pagination<NetworkMember[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/networks/${networkUuid}/members`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listNetworkMemberJobApplications(
    networkMemberUuid: string,
    params: {
      page: number;
      connector_job_post?: string;
    },
  ): Promise<ServiceResponse<Pagination<JobApplication[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/network-members/${networkMemberUuid}/job-applications`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async importNetworkMembersCSVPreview(
    networkUuid: string,
    body: FormData,
  ): Promise<ServiceResponse<{ valid: boolean; network_members: NetworkMember[] }>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/networks/${networkUuid}/import/csv/preview`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async importNetworkMemberResume(networkUuid: string, body: FormData): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/networks/${networkUuid}/import/resume`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async importNetworkMembersCSV(networkUuid: string, body: FormData): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/networks/${networkUuid}/import/csv`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async subscribeNetworkMember(networkUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/networks/${networkUuid}/subscribe`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async subscribeUnauthenticatedNetworkMember(
    networkUuid: string,
    body: { email: string },
  ): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/networks/${networkUuid}/subscribe/unauthenticated`,
        data: body,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async isSubscribedToNetwork(networkUuid: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/networks/${networkUuid}/subscribed`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async listNetworkMemberships(userUuid: string): Promise<ServiceResponse<Network[]>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/users/${userUuid}/network-memberships`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //       DOCUMENTS
  // ==================================

  async createNetworkMemberDocumentForm(
    networkMemberUuid: string,
    body: FormData,
  ): Promise<ServiceResponse<BaseDocument>> {
    try {
      const { data } = await this._makeRequest({
        method: "POST",
        url: `/network-members/${networkMemberUuid}/documents/`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async deleteNetworkMemberDocument(networkMemberUuid: string, documentUuid: string): Promise<ServiceResponse<{}>> {
    try {
      const { data } = await this._makeRequest({
        method: "DELETE",
        url: `/network-members/${networkMemberUuid}/documents/${documentUuid}/`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //      WALLET (A.K.A. LEDGER)
  // ==================================
  async getWalletEntries(
    walletUuid: string,
    params: { page: number },
  ): Promise<ServiceResponse<Pagination<LedgerEntry[]>>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/wallets/${walletUuid}/entries`,
        params,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //       JOB POST OPTIONS
  // ==================================

  async getJobPostOptions(): Promise<ServiceResponse<any>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/jobs/options`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //       ANALYTICS
  // ==================================

  async getConnectorOrganizationAnalytics(connectorOrganizationUuid: string): Promise<ServiceResponse<Analytics>> {
    try {
      const { data } = await this._makeRequest({
        method: "GET",
        url: `/connector-organizations/${connectorOrganizationUuid}/analytics`,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //              MISC
  // ==================================

  async resendVerifyEmail(userUuid: string): Promise<ServiceResponse<boolean>> {
    try {
      await this._makeRequest({
        method: "POST",
        url: `/users/${userUuid}/verify-email/resend`,
      });
      return {
        data: true,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //           AI PROMPTS
  // ==================================
  async completePrompt(body: {
    prompt_variant: PromptVariant;
    user_input: string;
    extra_data: {
      job_post_uuid?: string;
    };
  }): Promise<ServiceResponse<{ uuid: string; result: string }>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        url: `/ai/prompts/`,
        data: body,
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //       EMPLOYER ONBOARDING
  // ==================================
  async createEmployerOnboarding(body: {
    email: string;
    job_post_url: string;
    job_title: string;
  }): Promise<ServiceResponse<BaseEmployerOnboarding>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        url: "/employer-onboarding/",
        data: body,
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getEmployerOnboarding(employer_onboarding_uuid: string): Promise<ServiceResponse<BaseEmployerOnboarding>> {
    try {
      const res = await this._makeRequest({
        method: "GET",
        url: `/employer-onboarding/${employer_onboarding_uuid}/`,
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async employerOnboardingSetContract(employer_onboarding_uuid: string, data): Promise<ServiceResponse<JobPost>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        url: `/employer-onboarding/${employer_onboarding_uuid}/set-contract/`,
        data,
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  // ==================================
  //            ZELDA
  // ==================================
  async createZeldaUser(body: {
    first_name: string;
    last_name: string;
    phone_number: string;
  }): Promise<ServiceResponse<ZeldaUser>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        url: "/zelda/user/",
        data: body,
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async startChat(zelda_user_id: string): Promise<ServiceResponse<void>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        url: `/zelda/chat/start/${zelda_user_id}`,
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async sendDialogueAndFetchResponse(
    messages: string[],
    role: UserRole,
    sessionId: string,
  ): Promise<ServiceResponse<{ next_message: string; sessionId: string }>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        url: `/zelda/chatbot/`,
        data: {
          messages: messages,
          role: role,
          session_id: sessionId,
        },
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async sendResume(body: FormData): Promise<ServiceResponse<{ next_message: string; sessionId: string }>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        url: `/zelda/chatbot/resume/`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async chatBasedOnJob(
    messages: any,
    role: UserRole,
    jobId: string,
  ): Promise<ServiceResponse<{ next_message: string; sessionId: string }>> {
    try {
      const res = await this._makeRequest({
        method: "POST",
        data: {
          messages: messages,
          role: role,
          job_id: jobId,
        },
        url: `/zelda/chatbot/job/`,
      });
      return {
        data: res.data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }
}
