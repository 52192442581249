import axios from "axios";
import { ChatwootIdentifierResponseData } from "pages/api/chatwoot_identifier";
import { useEffect } from "react";
import { UserRole } from "types/userRole";

import { useApp } from "@/lib/context/app-context";

export const useChatwoot = () => {
  const { user, organization } = useApp();

  useEffect(() => {
    const setUser = async () => {
      try {
        const res = await axios.get<ChatwootIdentifierResponseData>("/api/chatwoot_identifier");
        const identifier_hash = res.data.identifier_hash;

        window.$chatwoot.setUser(user.uuid, {
          name: user.full_name,
          avatar_url: user.profile_picture,
          email: user.email,
          company_name: (user.selected_role === UserRole.Employer && organization?.name) || undefined,
          identifier_hash: identifier_hash, // Identifier Hash generated based on the webwidget hmac_token
        });
      } catch {}
    };

    if (!!user && typeof window.$chatwoot !== "undefined") {
      setUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uuid]);
};
