import Image from "next/image";

import classNames from "classnames";
import { FC } from "react";

interface OrganizationLogoProps {
  name: string;
  src?: string;
  alt?: string;
  size: "6" | "8" | "12" | "16" | "20";
  className?: string;
}

const sizeLookup = {
  "6": {
    box: "h-6 w-6",
    text: "text-base",
  },
  "8": {
    box: "h-8 w-8",
    text: "text-lg",
  },
  "12": {
    box: "h-12 w-12",
    text: "text-3xl",
  },
  "16": {
    box: "h-16 w-16",
    text: "text-4xl",
  },
  "20": {
    box: "h-20 w-20",
    text: "text-5xl",
  },
};

export const OrganizationLogo: FC<OrganizationLogoProps> = ({ src, alt, size, className, name }) => {
  let altText = alt || `${name}'s logo`;
  let firstLetter = name?.split("")[0] || "U";
  return (
    <div className={`relative shrink-0 overflow-hidden duration-200 ease-in-out ${sizeLookup[size].box}`}>
      {!!src ? (
        <Image className={classNames("w-full h-full", className)} src={src} alt={altText} fill />
      ) : (
        <div className="flex h-full w-full select-none items-center justify-center bg-zettlor-gray-900">
          <p className={`text-center text-white ${sizeLookup[size].text}`}>{firstLetter}</p>
        </div>
      )}
    </div>
  );
};
