import { zodResolver } from "@hookform/resolvers/zod";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BaseEmployerOnboarding } from "types/models/employerOnboardingBase";
import isUrl from "validator/lib/isURL";
import { z } from "zod";

import { trackEvent } from "components/analytics-wrapper/analytics-wrapper";
import { Input } from "components/form-inputs";
import { Button, Notice, Text } from "components/ui";

import { ErrorObject } from "@/lib/errors";
import { workEmailSchema } from "@/lib/validation";

import ZettlorService from "services/zettlor-service";

import { EmployerOnboardingVariant } from "./employer-onboarding-dialog";

const toggleGroupItemClasses =
  "w-full flex-1 data-[state=on]:bg-white flex h-[35px] w-[35px] items-center justify-center text-zettlor-new-black text-base leading-4 rounded-lg focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-[#6D82CC]";

const ToggleGroupDemo = ({ value, handleValueChange }) => (
  <div>
    <label className="font-480 mb-1 flex text-[14px] leading-snug text-zettlor-new-black/80">
      <span>Do you have a specific job in mind?</span>
    </label>
    <ToggleGroup.Root
      className="inline-flex w-full gap-2 rounded-lg bg-zettlor-new-black/[0.04] p-1"
      type="single"
      defaultValue="yes"
      value={value}
      aria-label="Text alignment"
    >
      <ToggleGroup.Item
        className={toggleGroupItemClasses}
        value="yes"
        aria-label="Yes"
        onClick={() => handleValueChange("yes")}
      >
        Yes
      </ToggleGroup.Item>
      <ToggleGroup.Item
        className={toggleGroupItemClasses}
        value="no"
        aria-label="No"
        onClick={() => handleValueChange("no")}
      >
        No
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  </div>
);

const onboardingFormSchema = z
  .object({
    email: workEmailSchema,
    isHiring: z.enum(["yes", "no"]),
    jobPostUrl: z.string().optional(),
    jobTitle: z.string().optional(),
  })
  // If isHiring is true, then jobPostUrl is required, otherwise jobTitle is required
  .refine((val) => (!!val.isHiring && val.isHiring === "yes") || (!!val.jobTitle && val.jobTitle?.length > 0), {
    message: "Enter either a job post URL or job title",
    path: ["jobPostUrl"],
  })
  .refine((val) => (!!val.isHiring && val.isHiring === "no") || (!!val.jobPostUrl && val.jobPostUrl?.length > 0), {
    message: "Enter either a job post URL or job title",
    path: ["jobTitle"],
  });

export const DISBURSEMENT_SCHEDULE_OPTIONS = [
  { label: "Every day", value: "DAILY", human: "Day", term: 1 },
  { label: "Every week", value: "WEEKLY", human: "Week", term: 1 },
  { label: "Every month", value: "MONTHLY", human: "Month", term: 1 },
  { label: "Every quarter", value: "QUARTERLY", human: "Quarter", term: 1 },
  { label: "Every year", value: "ANNUALLY", human: "Year", term: 1 },
];

type OnboardingFormData = {
  email: string;
  isHiring: "yes" | "no";
  jobPostUrl: string;
  jobTitle: string;
};

interface OnboardingFormStepProps {
  onSuccess: (_employerOnboarding: BaseEmployerOnboarding) => void;
  variant: EmployerOnboardingVariant;
}

const contentLookup = {
  publish: {
    title: "Draft your Zettlor powered job posting",
    description:
      "Generate a preview of your job posting, then publish it your employees' network for free and Zettlor’s network when you’re ready.",
    cta: "Generate preview",
  },
  // launch: {
  //   title: "Launch your referral program",
  //   description:
  //     "Generate a preview of your employee referral program for free, then publish it your employees and the Zettlor’s network when you’re ready.",
  //   cta: "Launch program",
  // },
};

const zettlorService = new ZettlorService();
export const OnboardingFormStep = ({ onSuccess, variant }: OnboardingFormStepProps) => {
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState<ErrorObject>();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<OnboardingFormData>({
    resolver: zodResolver(onboardingFormSchema),
    defaultValues: {
      isHiring: "yes",
    },
  });

  const onSubmit = async (formData: OnboardingFormData) => {
    // setLoading(true);

    let jobPostUrl;
    let jobTitle;

    if (formData.isHiring === "yes") {
      if (isUrl(formData.jobPostUrl)) {
        jobPostUrl = formData.jobPostUrl;
        jobTitle = null;
      } else {
        jobTitle = formData.jobPostUrl;
        jobPostUrl = null;
      }
    } else {
      jobTitle = formData.jobTitle;
      jobPostUrl = null;
    }

    const { success, error, data } = await zettlorService.createEmployerOnboarding({
      email: formData.email.toLowerCase(),
      job_post_url: jobPostUrl || undefined,
      job_title: jobTitle || undefined,
    });
    if (!success) {
      setLoading(false);
      return setApiErrors(error);
    }

    trackEvent("Employer Onboarding Dialog Form Submitted", {});
    setApiErrors(undefined);

    onSuccess(data);
  };

  const openChatBot = () => {
    window.$chatwoot.toggle();
  };

  const isHiring = watch("isHiring");

  return (
    <div className="relative h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <div className="px-6 pt-6 sm:px-8 sm:pt-8">
          <Text variant="h5" weight="font-560">
            {contentLookup[variant].title}
          </Text>
          <Text variant="b3" className="mb-5 mt-1 text-zettlor-new-black/80" weight="font-460">
            {contentLookup[variant].description}
          </Text>
        </div>
        <div className="space-y-5 px-6 pb-8 sm:px-8 sm:pb-0">
          <Notice variant="error">{apiErrors?.fields?.non_field_errors?.[0] || apiErrors?.message}</Notice>

          <Input<OnboardingFormData>
            label="Work email"
            name="email"
            placeholder="email@company.com"
            required
            type="email"
            inputMode="email"
            autoComplete="email"
            register={register}
            error={errors.email?.message || apiErrors?.fields?.email?.[0]}
          />

          <Controller
            name="isHiring"
            control={control}
            render={({ field: { onChange, value } }) => <ToggleGroupDemo value={value} handleValueChange={onChange} />}
          />

          {isHiring === "yes" ? (
            <div>
              <Input<OnboardingFormData>
                label="Job Title or Job Posting URL"
                name="jobPostUrl"
                placeholder="Software Engineer or https://company.ashby.com/jobs/job-title"
                type="text"
                required
                register={register}
                wrapperClassName="flex-1 w-full"
                error={errors.jobPostUrl?.message || apiErrors?.fields?.job_post_url?.[0]}
                description=""
              />
              <Text variant="b4" className="mt-2 text-zettlor-new-black/60" weight="font-480">
                Get started with a single job post, then add more via ATS integration or bulk upload.
              </Text>
            </div>
          ) : (
            <div>
              <Input<OnboardingFormData>
                label="Example Job Title"
                name="jobTitle"
                defaultValue="Software Engineer"
                type="text"
                required
                register={register}
                wrapperClassName="flex-1 w-full"
                error={errors.jobTitle?.message || apiErrors?.fields?.job_title?.[0]}
              />
              <Text variant="b4" className="mt-2 text-zettlor-new-black/60" weight="font-480">
                Enter an example job title to get started.
              </Text>
            </div>
          )}
        </div>
        <div className="sticky bottom-0 flex w-full justify-between border-t border-zettlor-new-black/20 bg-zettlor-new-off-white px-6 py-4 text-center sm:absolute sm:py-5">
          <div className="text-left">
            <Text variant="b4" className="text-zettlor-new-black/60" weight="font-460">
              Have questions? Need help?
              <br />
              <div onClick={openChatBot} className="cursor-pointer text-zettlor-new-black/60 underline">
                Chat with our team.
              </div>
            </Text>
          </div>
          <div>
            <Button type="submit" loading={loading}>
              Generate preview
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
