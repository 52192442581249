import Link, { LinkProps } from "next/link";

import React, { forwardRef } from "react";

import classNames from "../../lib/utilities/class-names";

/**
 * Base props for the Button component
 */
export type ButtonBaseProps = {
  /** Button style variant */
  variant?: "primary" | "secondary" | "ghost" | "link" | "link-black";
  /** Shows loading spinner when true */
  loading?: boolean;
  /** Disables button interaction */
  disabled?: boolean;
  /** Click handler */
  onClick?: (_event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /** For Next.js Link - prevents page reload */
  shallow?: boolean;
  /** Truncates text content */
  truncate?: boolean;
};
export type ButtonProps = ButtonBaseProps &
  (
    | (Omit<JSX.IntrinsicElements["a"], "href" | "onClick"> & LinkProps)
    | (Omit<JSX.IntrinsicElements["button"], "onClick"> & { href?: never })
  );

/**
 * A versatile button component that supports multiple visual variants and can render as either a button or link.
 *
 * @example
 * ```tsx
 * // Primary button
 * <Button variant="primary" onClick={handleClick}>Click me</Button>
 *
 * // Link button
 * <Button href="/some-path" variant="link">Navigate</Button>
 *
 * // Loading state
 * <Button loading>Processing...</Button>
 * ```
 */
export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(function Button(
  props: ButtonProps,
  forwardedRef,
) {
  const {
    loading = false,
    variant = "primary",
    shallow,
    truncate,
    // attributes propagated from `HTMLAnchorProps` or `HTMLButtonProps`
    ...passThroughProps
  } = props;
  // Buttons are **always** disabled if we're in a `loading` state
  const disabled = props.disabled || loading;

  // If pass an `href`-attr is passed it's `<a>`, otherwise it's a `<button />`
  const isLink = typeof props.href !== "undefined";
  const elementType = isLink ? "a" : "button";

  const element = React.createElement(
    elementType,
    {
      ...passThroughProps,
      disabled,
      ref: forwardedRef,
      className: classNames(
        // base styles independent what type of button it is
        "transition-colors delay-50 ease-in-out text-lg font-520 leading-[20px]",
        "relative appearance-none focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-[#6D82CC]",
        !["link", "link-black"].includes(variant) && "px-3 py-[7px] items-center justify-center inline-flex rounded-lg",
        ["link", "link-black"].includes(variant) && !truncate && "inline-flex",

        // different styles depending on variant

        // PRIMARY
        variant === "primary" && (disabled ? "opacity-30" : "hover:bg-zettlor-new-orange/[0.88]"),
        variant === "primary" && "border border-zettlor-new-orange bg-zettlor-new-orange text-white",

        // SECONDARY
        variant === "secondary" && (disabled ? "opacity-30" : "hover:border-zettlor-new-black"),
        variant === "secondary" && "border border-zettlor-new-black/[0.12] bg-white text-zettlor-new-black",

        // Ghost
        variant === "ghost" && "bg-transparent hover:bg-zettlor-new-black/[0.08]",

        // LINK
        variant === "link" && (disabled ? "opacity-30" : "hover:underline"),
        variant === "link" && "border-none text-zettlor-new-orange bg-transparent",

        // LINK BLACK
        variant === "link-black" && (disabled ? "opacity-30" : "hover:underline"),
        variant === "link-black" && "border-none text-zettlor-new-black bg-transparent",

        // set not-allowed cursor if disabled
        loading ? "cursor-wait" : disabled ? "cursor-not-allowed" : "",

        props.className,
      ),
      // if we click a disabled button, we prevent going through the click handler
      onClick: disabled
        ? (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
          }
        : props.onClick,
    },
    <>
      {props.children}
      {loading && variant !== "link" && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <svg
            className={classNames("mx-4 h-6 w-6 animate-spin", variant === "primary" && "text-zettlor-new-black")}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </>,
  );

  return props.href ? (
    <Link passHref href={props.href} shallow={shallow && shallow} legacyBehavior>
      {element}
    </Link>
  ) : (
    element
  );
});

export default Button;
