import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import { RefCallBack } from "react-hook-form";
import type { DropdownIndicatorProps } from "react-select";
import ReactSelect, { components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

import { ErrorMessage, Text } from "components/ui";

export interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  variant?: "default" | "async" | "async-creatable";
  options?: SelectOption[];
  value?: SelectOption | SelectOption[];
  onChange: (_o: any) => void;
  loadOptions?: (_inputValue: string, _callback: (_options: SelectOption[]) => void) => void;
  defaultOptions?: SelectOption[];
  placeholder?: string;
  isMulti?: boolean;
  label?: string | ReactNode;
  description?: string;
  wrapperClassName?: string;
  id: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  isSearchable?: boolean;
  customStylingConfig?: {
    searchCursorMargin?: number;
    inlineRight?: boolean;
    inlineLeft?: boolean;
  };
  filterOption?: ((_option: FilterOptionOption<SelectOption>, _inputValue: string) => boolean) | null;
  inputRef?: RefCallBack;
  formatCreateLabel?: (_inputValue: string) => string;
}

export interface SelectRecordOption {
  label: string;
  value: Record<string, string>;
}

export const customStyles = ({ searchCursorMargin = 0, inlineRight = false, inlineLeft = false }) => ({
  control: (provided, state) => {
    let focused = {};
    let error = {};
    let disabled = {};
    if (state.isFocused) {
      focused = {
        outline: "1px solid #6D82CC",
        outlineOffset: "2px",
        borderColor: "rgb(34 34 34 / 0.2)", // zettlor-black/20,
      };
    }
    const hasError = state.selectProps?.className?.includes("error");
    if (hasError) {
      error = {
        borderColor: "#D04B00", // zettlor-red
      };
    }
    if (state.isDisabled) {
      disabled = {
        backgroundColor: "#F6F3EF",
        color: "#A7A19E",
        cursor: "not-allowed",
      };
    }

    return {
      ...provided,
      cursor: "pointer",
      borderColor: "rgb(34 34 34 / 0.2)", // zettlor-black/20,
      boxShadow: "none",
      backgroundColor: "#FAFAFA", // zettlor-off-white
      borderRadius: inlineLeft ? "8px 0 0 8px" : inlineRight ? "0 8px 8px 0" : "8px",
      minHeight: "36px",
      ...focused,
      ...disabled,
      ...error,
    };
  },
  option: (provided, state) => {
    let focused = {};
    let selected = {};
    let hovered = {};
    if (state.isFocused && !state.isSelected) {
      focused = {
        backgroundColor: "rgb(34 34 34 / 0.08)", // zettlor-black/8,
      };
    }
    if (state.isSelected) {
      selected = {
        color: "#494644",
        backgroundColor: "rgb(34 34 34 / 0.2)", // zettlor-black/20,
      };
    }
    if (!state.isSelected) {
      hovered = {
        ":hover": {
          backgroundColor: "rgb(34 34 34 / 0.08)", // zettlor-black/8,
        },
      };
    }

    return {
      ...provided,
      cursor: "pointer",
      ...focused,
      ...selected,
      ...hovered,
    };
  },
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#FAFAFA", // zettlor-off-white
    borderColor: "rgb(34 34 34 / 0.2)", // zettlor-black/20,
    borderRadius: "8px",
    overflow: "hidden",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#A7A19E", // zettlor-gray
    fontSize: "15px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontSize: "16px",
    padding: state.isMulti && state.hasValue ? "5px 8px 5px 8px" : "5px 10px 5px 10px",
    borderRadius: "8px",
  }),
  input: (provided) => ({
    ...provided,
    margin: `0 0 0 ${searchCursorMargin || 0}px`,
    padding: "0",
    borderRadius: "8px",
    fontSize: "15px",
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "rgb(34 34 34 / 0.08)", // zettlor-black/8,
      padding: "1px 0px 1px 6px",
      borderRadius: "4px",
    };
  },
  multiValueRemove: (provided, { data }) => ({
    ...provided,
    color: data.color,
    ":hover": {
      backgroundColor: "rgb(34 34 34 / 0.08)", // zettlor-black/8,
    },
  }),
});

export const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral50: "#A7A19E", // Placeholder color
    primary: "rgb(34 34 34 / 0.2)", // zettlor-black/20,
  },
});

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="h-4 w-4 text-zettlor-new-black/80" />
    </components.DropdownIndicator>
  );
};

export const Select: FC<SelectProps> = ({
  variant = "default",
  options,
  value,
  onChange,
  loadOptions,
  defaultOptions,
  placeholder,
  isMulti = false,
  label,
  description,
  id,
  required,
  wrapperClassName,
  error,
  isSearchable = true,
  disabled = false,
  customStylingConfig = {},
  filterOption,
  inputRef,
  formatCreateLabel,
}) => {
  let Component;
  switch (variant) {
    case "async":
      Component = AsyncSelect;
      break;
    case "async-creatable":
      Component = AsyncCreatableSelect;
      break;
    default:
      Component = ReactSelect;
      break;
  }
  return (
    <div className={wrapperClassName}>
      {label && (
        <label htmlFor={id} className="font-480 mb-1 flex space-x-1 text-[14px] leading-snug text-zettlor-new-black/80">
          <span>{label}</span>
          {required ? <span className="text-orange-500">*</span> : ""}
        </label>
      )}
      {description && (
        <Text variant="b3" className="mb-2 text-zettlor-new-black/80" weight="font-460">
          {description}
        </Text>
      )}
      <Component
        id={id}
        instanceId={id}
        ref={inputRef}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        value={value}
        isMulti={isMulti}
        filterOption={filterOption}
        className={classNames("w-full", !!error ? "error" : "")}
        styles={customStyles(customStylingConfig)}
        theme={customTheme}
        isDisabled={disabled}
        isSearchable={isSearchable}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        formatCreateLabel={formatCreateLabel}
      />
      <ErrorMessage error={error} />
    </div>
  );
};
