import classNames from "classnames";
import { ChangeEvent, ClipboardEvent, ReactNode, useState } from "react";
import { Path, RefCallBack, UseFormRegister } from "react-hook-form";

import { ErrorMessage, Text } from "components/ui";

// Register type from: https://stackblitz.com/edit/reusable-rhf-ts-pt6?file=src%2Fcomponents%2Fmolecules%2Fform-input.tsx
interface InputProps<TFormValues> {
  name: Path<TFormValues>;
  register?: UseFormRegister<TFormValues>;
  label?: string | ReactNode;
  description?: string;
  defaultValue?: string | number;
  type?: "button" | "email" | "number" | "password" | "text" | "textarea" | "url" | "tel";
  inputMode?: "email" | "numeric" | "tel" | "text" | "url";
  autoComplete?:
    | "email"
    | "given-name"
    | "family-name"
    | "new-password"
    | "current-password"
    | "tel-national"
    | "one-time-code"
    | "off";
  required?: boolean;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  rows?: number;
  inputClassName?: string;
  inputWrapperClassName?: string;
  wrapperClassName?: string;
  value?: any;
  preText?: string | ReactNode;
  inputRef?: RefCallBack;
  onChange?: (_e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => any;
  onPaste?: (_e: ClipboardEvent<HTMLInputElement>) => any;
}

export const Input = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  label,
  description,
  error,
  defaultValue,
  required = false,
  disabled = false,
  type = "text",
  inputMode = "text",
  autoComplete = "off",
  placeholder,
  rows = 3,
  inputClassName,
  inputWrapperClassName,
  wrapperClassName,
  value,
  onChange,
  onPaste,
  preText,
  inputRef,
}: InputProps<TFormValues>): JSX.Element => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={wrapperClassName}>
      {label && (
        <label
          htmlFor={name}
          className="font-480 mb-1 flex space-x-1 text-[14px] leading-snug text-zettlor-new-black/80"
        >
          <span>{label}</span>
          {required ? <span className="text-orange-500">*</span> : ""}
        </label>
      )}
      {description && (
        <Text variant="b3" className="mb-2 text-zettlor-new-black/80" weight="font-460">
          {description}
        </Text>
      )}
      <div
        className={classNames(
          "flex",
          inputWrapperClassName,
          focused ? "ring-1 ring-offset-2 ring-[#6D82CC] rounded-lg" : "",
        )}
      >
        {type === "textarea" ? (
          <textarea
            {...(register ? register(name) : {})}
            {...(onChange && !register ? { onChange, value } : {})}
            {...(inputRef ? { ref: inputRef } : {})}
            required={required}
            name={name}
            id={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            rows={rows}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            className={classNames(
              "flex-1 block w-full px-2.5 py-[7px] rounded-lg text-base leading-tight placeholder-zettlor-gray-500 bg-zettlor-new-off-white",
              "border-zettlor-new-black/20 focus:border-zettlor-new-black/20 focus:ring-0 focus:outline-0	focus:shadow-none",
              !!error ? "bg-red-100 border-zettlor-danger-red" : "",
              disabled ? "bg-zettlor-gray-200 text-zettlor-gray-500 hover:cursor-not-allowed" : "",
              inputClassName,
            )}
          />
        ) : (
          <>
            {preText && (
              <div
                className={classNames(
                  "flex items-center justify-center text-base rounded-l-lg pl-3 pr-1 border-l border-y text-zettlor-new-black border-zettlor-new-black/20",
                  disabled ? "border-zettlor-new-black/20 bg-zettlor-gray-200" : "bg-zettlor-new-off-white",
                )}
                onClick={(element) => {
                  // Since the pretext can be a node, we need to traverse the dom to find the input to focus.
                  const traverse = [
                    // @ts-ignore
                    element?.target?.nextElementSibling,
                    // @ts-ignore
                    element?.target?.parentElement?.nextElementSibling,
                    // @ts-ignore
                    element?.target?.parentElement?.parentElement?.nextElementSibling,
                    // @ts-ignore
                    element?.target?.parentElement?.parentElement?.parentElement?.nextElementSibling,
                  ];

                  for (const el of traverse) {
                    if (el && el?.tagName === "INPUT") {
                      el?.focus();
                      break;
                    }
                  }
                }}
              >
                {preText}
              </div>
            )}
            <input
              {...(register ? register(name) : {})}
              {...(onChange && !register ? { onChange, value } : {})}
              {...(inputRef ? { ref: inputRef } : {})}
              // note(zach): This is a fix to preview the scrolling number default behavior
              // https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
              {...(type === "number"
                ? {
                    min: 0,
                    onWheel: (e) => {
                      e.currentTarget.blur();
                    },
                  }
                : {})}
              type={type}
              inputMode={inputMode}
              autoComplete={autoComplete}
              required={required}
              name={name}
              disabled={disabled}
              id={name}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onPaste={onPaste || undefined}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
              }}
              className={classNames(
                "flex-1 block w-full px-2.5 py-[7px] text-base leading-tight placeholder-zettlor-gray-500 bg-zettlor-new-off-white",
                "border-zettlor-new-black/20 focus:border-zettlor-new-black/20 focus:ring-0 focus:outline-0	focus:shadow-none",
                !!error ? "!border-red-500" : "",
                !!preText ? "border-l-0 pl-0.5 rounded-r-lg" : "rounded-lg",
                disabled ? "bg-zettlor-new-black/[0.08] text-zettlor-gray-500 hover:cursor-not-allowed" : "",
                inputClassName,
              )}
            />
          </>
        )}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};
