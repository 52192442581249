import { RadioGroup as HeadlessUIRadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { FC, ReactNode } from "react";

import { Text } from "components/ui";

type Option = {
  id: string;
  title: string;
  icon: ReactNode;
  description: string;
};

interface RadioGroupProps {
  options: Option[];
  value: Option;
  onChange: (_value: Option) => void;
}

export const RadioGroup: FC<RadioGroupProps> = ({ options, value, onChange }) => {
  return (
    <HeadlessUIRadioGroup value={value} onChange={onChange}>
      <div className="space-y-4">
        {options.map((option) => (
          <HeadlessUIRadioGroup.Option
            key={option.id}
            value={option}
            className={({ checked, active }) =>
              classNames(
                checked
                  ? "border-red-500 bg-zettlor-new-black/[0.04]"
                  : "bg-zettlor-new-off-white border-zettlor-new-black/20",
                active ? "border-red-500 ring-1 ring-offset-2 ring-[#6D82CC]" : "",
                "relative block cursor-pointer border-2 rounded-lg outline-none p-4 sm:flex sm:justify-between hover:bg-zettlor-new-black/[0.04]",
              )
            }
          >
            {() => (
              <>
                <div className="flex cursor-pointer items-start space-x-2">
                  <div className="mt-0.5">{option.icon}</div>
                  <div className="min-w-0 flex-1 select-none text-sm">
                    <label className="cursor-pointer">
                      <Text variant="h5">{option.title}</Text>
                      <Text variant="b3">{option.description}</Text>
                    </label>
                  </div>
                </div>
              </>
            )}
          </HeadlessUIRadioGroup.Option>
        ))}
      </div>
    </HeadlessUIRadioGroup>
  );
};
