import { UserRole } from "types/userRole";

import { BaseOrganization } from ".";
import { BaseUser } from "./userBase";

export enum InvitationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
}

export type BaseInvitation = {
  // Core
  uuid: string;
  status: InvitationStatus;

  // Data
  email?: string;
  phone_number?: string;
  first_name: string;
  last_name: string;
  role: UserRole;

  // Relationships
  user?: BaseUser;
  organization: BaseOrganization;

  // Timestamps
  expires_at?: string;
  created_at: string;
  updated_at: string;
};
