import classNames from "classnames";
import { FC } from "react";
import { ConnectorJobPostStatus, InvitationStatus, JobApplicationStatus, JobPostStatus } from "types/models";
import { LedgerTransactionStatus } from "types/models/ledgerTransactionBase";
import { UserRole } from "types/userRole";

type StatusEmployerTagVariant = "job_post" | "job_application" | "invitation" | "transaction";
type StatusConnectorTagVariant = "job_application" | "connector_job_post" | "debit_transaction" | "credit_transaction";
type StatusWorkerTagVariant = "job_application";
type StatusTagVariant = StatusEmployerTagVariant | StatusConnectorTagVariant | StatusWorkerTagVariant;
type StatusTagConfig<T extends string> = {
  [_K in T]: {
    [key: string]: {
      color: string;
      label: string;
    };
  };
};

export const EMPLOYER_STATUS_TAG_LOOKUP: StatusTagConfig<StatusEmployerTagVariant> = {
  job_post: {
    [JobPostStatus.OPEN]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Active" },
    [JobPostStatus.DRAFT]: { color: "bg-zettlor-gray-400", label: "Draft" },
    [JobPostStatus.ARCHIVED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Archived" },
    [JobPostStatus.CLOSED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Closed" },
  },
  job_application: {
    [JobApplicationStatus.INVITED]: { color: "bg-zettlor-yellow", label: "Needs Review" },
    [JobApplicationStatus.SOURCED]: { color: "bg-zettlor-yellow", label: "Sourced" },
    [JobApplicationStatus.SUBMITTED]: { color: "bg-zettlor-yellow", label: "Needs Review" },
    [JobApplicationStatus.ACCEPTED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Interviewing" },
    [JobApplicationStatus.ARCHIVED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Archived" },
    [JobApplicationStatus.WITHDRAWN]: { color: "bg-orange-300", label: "Withdrawn" },
    [JobApplicationStatus.REJECTED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Rejected" },
    [JobApplicationStatus.HIRED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Hired" },
  },
  invitation: {
    [InvitationStatus.ACCEPTED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Accepted" },
    [InvitationStatus.CANCELLED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Cancelled" },
    [InvitationStatus.PENDING]: { color: "bg-zettlor-yellow", label: "Pending" },
    [InvitationStatus.EXPIRED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Expired" },
  },
  transaction: {
    pending: { color: "bg-zettlor-yellow", label: "Pending" },
    posted: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Paid" },
    archived: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Archived" },
  },
};

export const CONNECTOR_STATUS_TAG_LOOKUP: StatusTagConfig<StatusConnectorTagVariant> = {
  connector_job_post: {
    [ConnectorJobPostStatus.OFFERED]: { color: "bg-orange-200", label: "Offer submitted" },
    [ConnectorJobPostStatus.COUNTERED]: {
      color: "bg-zettlor-green-500 text-zettlor-gray-100",
      label: "Offer countered",
    },
    [ConnectorJobPostStatus.REJECTED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Offer rejected" },
    [ConnectorJobPostStatus.OPEN]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Active" },
    [ConnectorJobPostStatus.ARCHIVED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Archived" },
  },
  job_application: {
    [JobApplicationStatus.INVITED]: { color: "bg-zettlor-yellow", label: "Invited" },
    [JobApplicationStatus.SOURCED]: { color: "bg-zettlor-yellow", label: "Sourced" },
    [JobApplicationStatus.SUBMITTED]: { color: "bg-zettlor-yellow", label: "Submitted" },
    [JobApplicationStatus.ACCEPTED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Interviewing" },
    [JobApplicationStatus.ARCHIVED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Archived" },
    [JobApplicationStatus.WITHDRAWN]: { color: "bg-orange-300", label: "Withdrawn" },
    [JobApplicationStatus.REJECTED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Rejected" },
    [JobApplicationStatus.HIRED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Hired" },
    [JobApplicationStatus.EXPIRED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Expired" },
  },
  debit_transaction: {
    [LedgerTransactionStatus.PENDING]: { color: "bg-zettlor-yellow", label: "Pending" },
    [LedgerTransactionStatus.POSTED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Withdrawn" },
    [LedgerTransactionStatus.ARCHIVED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Cancelled" },
  },
  credit_transaction: {
    [LedgerTransactionStatus.PENDING]: { color: "bg-zettlor-yellow", label: "Pending" },
    [LedgerTransactionStatus.POSTED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Earned" },
    [LedgerTransactionStatus.ARCHIVED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Cancelled" },
  },
};

export const WORKER_STATUS_TAG_LOOKUP: StatusTagConfig<StatusWorkerTagVariant> = {
  job_application: {
    [JobApplicationStatus.INVITED]: { color: "bg-zettlor-yellow", label: "Invited" },
    [JobApplicationStatus.SOURCED]: { color: "bg-zettlor-yellow", label: "Sourced" },
    [JobApplicationStatus.SUBMITTED]: { color: "bg-zettlor-yellow", label: "Submitted" },
    [JobApplicationStatus.ACCEPTED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Interviewing" },
    [JobApplicationStatus.ARCHIVED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Archived" },
    [JobApplicationStatus.WITHDRAWN]: { color: "bg-orange-300", label: "Withdrawn" },
    [JobApplicationStatus.REJECTED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Rejected" },
    [JobApplicationStatus.HIRED]: { color: "bg-zettlor-green-500 text-zettlor-gray-100", label: "Hired" },
    [JobApplicationStatus.EXPIRED]: { color: "bg-zettlor-red text-zettlor-gray-100", label: "Expired" },
  },
};

interface StatusTagProps {
  variant: StatusTagVariant;
  role: UserRole;
  status: ConnectorJobPostStatus | JobPostStatus | JobApplicationStatus | InvitationStatus | LedgerTransactionStatus;
}

export const StatusTag: FC<StatusTagProps> = ({ status, role, variant }) => {
  let STATUS_TAG_LOOKUP;
  switch (role) {
    case UserRole.Employer:
      STATUS_TAG_LOOKUP = EMPLOYER_STATUS_TAG_LOOKUP;
      break;
    case UserRole.Connector:
      STATUS_TAG_LOOKUP = CONNECTOR_STATUS_TAG_LOOKUP;
      break;
    case UserRole.Worker:
      STATUS_TAG_LOOKUP = WORKER_STATUS_TAG_LOOKUP;
      break;
  }

  return (
    <p
      className={classNames(
        "inline-flex items-center px-3 py-2 text-sm font-500 capitalize rounded-full font-medium shrink-0",
        STATUS_TAG_LOOKUP[variant][status].color,
      )}
    >
      {STATUS_TAG_LOOKUP[variant][status].label}
    </p>
  );
};
