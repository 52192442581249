import { AppProps } from "next/app";
import { useRouter } from "next/router";

import { TooltipProvider } from "@radix-ui/react-tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import "styles/globals.css";
import { User } from "types/models";

import { AnalyticsWrapper } from "components/analytics-wrapper/analytics-wrapper";

import { AppProvider } from "@/lib/context/app-context";
import { FeatureFlagProvider } from "@/lib/context/feature-flag-context";
import * as fbq from "@/lib/fb-pixel";

const StagingNotice = () => (
  <div className="fixed bottom-4 left-4 bg-yellow-200 p-4">
    <h1>You are currently on staging.</h1>
  </div>
);

export default function MyApp({
  Component,
  pageProps,
}: AppProps<{
  user?: User;
}>) {
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            style: {
              borderRadius: "8px",
            },
            error: {
              iconTheme: {
                primary: "#FBFAF6",
                secondary: "#AA1515",
              },
              style: {
                background: "#AA1515",
                color: "#FBFAF6",
              },
            },
            success: {
              iconTheme: {
                primary: "#FBFAF6",
                secondary: "#494644",
              },
              style: {
                background: "#494644",
                color: "#FBFAF6",
              },
            },
          }}
        />
      </div>
      <AppProvider>
        <FeatureFlagProvider>
          <TooltipProvider>
            <AnalyticsWrapper />
            <Component {...pageProps} />
            <SpeedInsights />
          </TooltipProvider>
        </FeatureFlagProvider>
      </AppProvider>
      {process.env.NEXT_PUBLIC_NODE_ENV === "staging" && <StagingNotice />}
      {process.env.NEXT_PUBLIC_NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}
