import { ChevronDownIcon } from "@heroicons/react/24/outline";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import React, { FC, ReactNode } from "react";

interface ToggleBoxProps {
  children: ReactNode;
  className?: string;
  label: string | ReactNode;
  disabled?: boolean;
  defaultOpen?: boolean;
  handleToggle?: (_open: boolean) => void;
}

interface AccordionTriggerProps {
  children: ReactNode;
  disabled?: boolean;
}

interface AccordionContentProps {
  children: ReactNode;
}

export const ToggleBox: FC<ToggleBoxProps> = ({
  label,
  children,
  className,
  disabled = false,
  defaultOpen = false,
  handleToggle,
}) => (
  <Accordion.Root
    className={classNames("w-full border border-zettlor-new-black/20 rounded-lg", className)}
    type="single"
    disabled={disabled}
    defaultValue={defaultOpen ? "item-1" : undefined}
    collapsible
    onValueChange={(value) => {
      if (handleToggle) {
        handleToggle(value === "item-1");
      }
    }}
  >
    <Accordion.Item className="overflow-hidden" value="item-1">
      <AccordionTrigger disabled={disabled}>{label}</AccordionTrigger>
      <AccordionContent>{children}</AccordionContent>
    </Accordion.Item>
  </Accordion.Root>
);

const AccordionTrigger: FC<AccordionTriggerProps> = ({ children, disabled }) => (
  <Accordion.Header className="flex">
    <Accordion.Trigger
      className={classNames(
        "group flex flex-1 items-center justify-between bg-white px-4 py-4 text-base font-medium text-zettlor-new-black outline-none hover:bg-zettlor-new-black/5 disabled:cursor-not-allowed disabled:opacity-50",
        disabled && "cursor-not-allowed opacity-50",
      )}
      disabled={disabled}
    >
      {children}
      <ChevronDownIcon
        className="h-4 w-4 shrink-0 text-zettlor-new-black transition-transform duration-200 ease-out group-data-[state=open]:rotate-180"
        aria-hidden
      />
    </Accordion.Trigger>
  </Accordion.Header>
);

const AccordionContent: FC<AccordionContentProps> = ({ children }) => (
  <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
    <div className="px-4 py-4">{children}</div>
  </Accordion.Content>
);
