import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, FC } from "react";

import { ACCEPTED_DOCUMENT_TYPES, ACCEPTED_IMAGE_TYPES, MAX_FILE_SIZE_HUMAN_READABLE } from "@/lib/constants";

import { Notice } from "./notice";
import { Spinner } from "./spinner";

interface FileUploadProps {
  onChange: (_e?: ChangeEvent<HTMLInputElement>) => void;
  fileUrl?: string;
  fileName?: string;
  title: string;
  type: "image" | "document";
  error?: string;
  loading?: boolean;
  required?: boolean;
}

const FILE_TYPE_LOOKUP = {
  image: {
    fileTypes: ACCEPTED_IMAGE_TYPES.join(","),
    fileTypesString: `(PNG, JPG, GIF up to ${MAX_FILE_SIZE_HUMAN_READABLE})`,
  },
  document: {
    fileTypes: ACCEPTED_DOCUMENT_TYPES.join(","),
    fileTypesString: `(PDF up to ${MAX_FILE_SIZE_HUMAN_READABLE})`,
  },
};

const renderFile = (type, fileUrl, fileName) => {
  switch (type) {
    case "image":
      return <img src={fileUrl} alt={fileName} className="rounded shadow" />;

    case "document":
      return (
        <div className="flex items-center space-x-1">
          <DocumentIcon className="h-6 w-6" aria-hidden="true" />
          <p className="text-base font-medium">{fileName}</p>
        </div>
      );

    default:
      return null;
  }
};

export const FileUpload: FC<FileUploadProps> = ({
  onChange,
  title,
  fileUrl = "",
  fileName,
  type,
  error,
  loading,
  required = false,
}) => {
  const titleId = title.toLowerCase().replace(/\s/g, "-");
  return (
    <div>
      <label className="font-480 mb-1 flex space-x-1 text-[14px] leading-snug text-zettlor-new-black/80">
        <span>{title}</span>
        {required ? <span className="text-orange-500">*</span> : ""}
      </label>
      <label
        htmlFor={titleId}
        className="cursor-pointer text-zettlor-new-orange focus:outline-none focus:ring-1 focus:ring-[#6D82CC] focus:ring-offset-2"
      >
        <div className="relative mt-1 flex justify-center rounded-lg border-2 border-dashed border-zettlor-new-orange p-8">
          {fileUrl && (
            <XMarkIcon
              className="absolute right-2 top-2 h-6 w-6 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                document.querySelector<HTMLInputElement>(`#${titleId}`).value = "";
                onChange(null);
              }}
            />
          )}
          <div className="space-y-3 text-center">
            {loading ? (
              <Spinner />
            ) : (
              <>
                {fileUrl && renderFile(type, fileUrl, fileName)}
                <div className="text-sm">
                  {/* TODO: add suggestion ratios */}
                  <span className="font-560 text-zettlor-new-black">
                    Click to {fileUrl ? `change ${type}` : `upload a ${type}`}
                  </span>
                  <input
                    id={titleId}
                    name={titleId}
                    type="file"
                    className="sr-only"
                    onChange={onChange}
                    accept={FILE_TYPE_LOOKUP[type].fileTypes}
                  />
                </div>
                {!fileUrl && (
                  <p className="font-460 text-xs text-zettlor-new-black/80">{FILE_TYPE_LOOKUP[type].fileTypesString}</p>
                )}
              </>
            )}
          </div>
        </div>
        <Notice className="mt-2" variant="error">
          {error}
        </Notice>
      </label>
    </div>
  );
};
