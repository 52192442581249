import dynamic from "next/dynamic";

import { FC } from "react";
import { RefCallBack } from "react-hook-form";

import { ErrorMessage } from "components/ui";

const QuillNoSSRWrapper = dynamic(() => import("react-quill"), {
  ssr: false,
  loading: () => <p>Loading ...</p>,
});

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = ["header", "size", "bold", "italic", "underline", "blockquote", "list", "bullet", "indent"];

interface RichTextExampleProps {
  onChange: (_e) => void;
  value: string;
  id: string;
  inputRef?: RefCallBack;
  error?: string;
}

export const RichTextEditor: FC<RichTextExampleProps> = ({ id, value, onChange, inputRef, error }) => {
  return (
    <>
      {/* @ts-ignore-next-line */}
      <div ref={inputRef}>
        <QuillNoSSRWrapper
          modules={modules}
          formats={formats}
          value={value}
          id={id}
          onChange={(e) => {
            onChange(e);
          }}
          theme="snow"
          className={!!error ? "error" : ""}
          placeholder="Write a description"
        />
        <ErrorMessage error={error} />
      </div>
    </>
  );
};
