import classNames from "classnames";
import { FC, ReactNode } from "react";

import { Text } from ".";

interface NoticeBarProps {
  label: string;
  actions?: ReactNode;
  isSticky?: boolean;
  flexMobile?: boolean;
}

export const NoticeBar: FC<NoticeBarProps> = ({ label, actions, isSticky = false, flexMobile = false }) => {
  return (
    <div className={classNames("mx-auto max-w-7xl", isSticky && "sticky top-0 z-[9]")}>
      <div
        className={classNames(
          "items-center justify-between bg-zettlor-yellow p-4",
          flexMobile ? "flex px-6" : "sm:flex sm:px-6",
        )}
      >
        <Text variant="h5">{label}</Text>
        <div className={classNames("flex space-x-2", flexMobile ? "" : "mt-2 sm:mt-0")}>{actions}</div>
      </div>
    </div>
  );
};
