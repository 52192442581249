import { SocialProvider } from "types/socialProviders";

import { BaseUser, JobPostOption } from ".";
import { BaseLocation } from "./locationBase";

export enum OrganizationMembershipRole {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
}

export type OrganizationMembership = {
  // Core
  role: OrganizationMembershipRole;

  // Relationships
  user: BaseUser;

  // Timestamps
  created_at: string;
  updated_at: string;
};

export type BaseOrganization = SocialProvider & {
  // Core
  uuid: string;
  name: string;
  tagline?: string;
  logo?: string;
  company_size?: string;
  is_verified: boolean;

  // Contact
  phone_number?: string;
  email?: string;
  is_claimed: boolean;

  // Reviews
  rating?: number;
  review_count?: number;

  // Third party
  has_merge_hris_account_token: boolean;
  has_merge_ats_account_token: boolean;
  has_stripe_customer_id: boolean;
  has_active_subscription: boolean;

  // Relationships
  handle: string;
  primary_location?: BaseLocation;
  industry?: JobPostOption;

  // Timestamps
  created_at: string;
  updated_at: string;
};
