import axios, { AxiosError } from "axios";

import { SUPPORT_EMAIL } from "./constants";

export interface ErrorObject {
  message?: string;
  code?: string;
  fields?: {
    [field: string]: string[];
  };
}

export interface FormattedApiError {
  error: ErrorObject;
  success: boolean;
}

export const parseApiError = (error: Error | AxiosError): FormattedApiError => {
  if (axios.isAxiosError(error)) {
    let errorObj = error.response?.data?.["error"] || {};
    if (error.response?.status === 500 || !errorObj["message"]) {
      errorObj = {
        message: "An unknown error occurred, please try again.",
        code: "unknown_error",
      };
    }
    return {
      error: errorObj,
      success: false,
    };
  } else {
    return {
      error: {
        message: "An unknown error occurred, please try again.",
        code: "unknown_error",
      },
      success: false,
    };
  }
};

// const getFirstApiError = (error: unknown) => {
//   if (!error) {
//     return "Unknown error";
//   }
//   if (typeof error === "string") {
//     return error.slice(0, 300);
//   }

//   const message = error["message"];
//   if (typeof message === "string") {
//     return message;
//   } else {
//     return message?.[0];
//   }
// };

export const authErrorParse = (error: string) => {
  switch (error) {
    // case "OAuthSignin":
    //   return "Error in constructing an authorization URL (1, 2, 3)";
    // case "OAuthCallback":
    //   return "Error in handling the response (1, 2, 3) from an OAuth provider";
    // case "OAuthCreateAccount":
    //   return "Could not create your account";
    // case "EmailCreateAccount":
    //   return "Could not create email provider user in the database";
    // case "Callback":
    //   return "Error in the OAuth callback handler rout";
    // case "OAuthAccountNotLinked":
    //   return "If the email on the account is already linked, but not with this OAuth account";
    // case "EmailSignin":
    //   return "Sending the e-mail with the verification token failed";
    case "CredentialsSignin":
      return "Your credentials are incorrect, please try again. If you've previously signed in with a Google or LinkedIn, please use that method to sign in.";
    case "SessionRequired":
      return "The content of this page requires you to be signed in.";
    case "ExistingAccountFound":
      return "You have an existing account with another sign in method, please use that other method.";
    case "Callback":
      return "There was a problem logging you in, please try again.";
    case "MissingUserRole":
      return "No account found, please sign up to continue.";
    case "InvalidInvitationState":
      return `This invitation is not valid, please contact ${SUPPORT_EMAIL}.`;
    case "InvitationAccepted":
      return "This invitation has already been accepted, please login in.";
    case "UnknownError":
      return "An unknown error occurred, please try again";
    default:
      return error;
  }
};

export const ERROR_TYPES = {
  job_post_not_found: {
    statusCode: "404",
    url: "/404?errorType=job_post_not_found",
    message: "The job page you tried to access doesn't exist.",
    actionText: "View all jobs",
    actionUrl: "/search",
  },
  employer_job_post_not_found: {
    statusCode: "404",
    url: "/404?errorType=employer_job_post_not_found",
    message: "The job page you tried to access doesn't exist.",
    actionText: "View your jobs",
    actionUrl: "/job-posts",
  },
  connector_job_post_not_found: {
    statusCode: "404",
    url: "/404?errorType=connector_job_post_not_found",
    message: "The job post you are looking for does not exist.",
    actionText: "Go home",
    actionUrl: "/",
  },
  organization_not_found: {
    statusCode: "404",
    url: "/404?errorType=organization_not_found",
    message: "The organization you are looking for does not exist.",
    actionText: "Go home",
    actionUrl: "/",
  },
  connector_profile_not_found: {
    statusCode: "404",
    url: "/404?errorType=connector_profile_not_found",
    message: "The profile you tried to access doesn't exist.",
    actionText: "Go home",
    actionUrl: "/",
  },
  default_not_found: {
    statusCode: "404",
    url: "/404?errorType=default_not_found",
    message: "Go back to our main page to continue your visit on Zettlor.",
    actionText: "Go home",
    actionUrl: "/",
  },
  invitation_not_valid: {
    statusCode: "400",
    url: "/404?errorType=invitation_not_valid",
    message:
      "The invitation you're trying to accept is not longer valid. Please contact your team administrator to get a new invitation. If you've already accepted the invitations, please try logging in.",
    actionText: "Login",
    actionUrl: "/auth/login",
  },
  referral_invitation_not_valid: {
    statusCode: "400",
    url: "/404?errorType=referral_invitation_not_valid",
    message:
      "The invitation you're trying to accept is not longer valid. Please contact your our team to get a new invitation at team@zettlor.com.",
    actionText: "Login",
    actionUrl: "/auth/login",
  },
  no_access: {
    statusCode: "401",
    url: "/404?errorType=no_access",
    message: "You don't have access to this page. Go back to our main page to continue your visit on Zettlor.",
    actionText: "Go home",
    actionUrl: "/",
  },
};
