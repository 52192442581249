import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ConnectorJobPost, JobPost } from "types/models";
import { BasePlacementContract } from "types/models/placementContractBase";

import { SelectOption } from "components/form-inputs/select";
import { Tooltip } from "components/ui/tooltip";

import { DISBURSEMENT_SCHEDULE_LABEL_LOOKUP, DISBURSEMENT_SCHEDULE_OPTIONS } from "./constants";
import { formatCurrencyForDisplay, formatCurrencyForServer } from "./currency";

// This is on the job post page for connectors
// Example: You will receive $1,000/month for 3 months.
// Example 2: You will receive $1,000/month for 3 months starting 1 month after the candidate’s start date.
export const placementContractSummary = (placementContract: BasePlacementContract) => {
  return (
    <span>
      {formatCurrencyForDisplay(placementContract.value / placementContract.term, {
        showCurrency: false,
        digits: 2,
        precision: 2,
      })}
      /{DISBURSEMENT_SCHEDULE_OPTIONS.find((ds) => ds.value === placementContract.disbursement)?.human.toLowerCase()}{" "}
      for {placementContract.term} {DISBURSEMENT_SCHEDULE_LABEL_LOOKUP[placementContract.disbursement]?.toLowerCase()}
      {placementContract.term > 1 ? "s" : ""}
      {placementContract.trial > 0
        ? ` starting ${placementContract.trial} ${DISBURSEMENT_SCHEDULE_LABEL_LOOKUP[
            placementContract.disbursement
          ]?.toLowerCase()}${placementContract.trial > 1 ? "s" : ""} after the candidate’s start date`
        : ""}
    </span>
  );
};

// This is on the job post tile for connectors
// Example: Paid monthly over 3 months
// Example 2: Paid monthly over 3 months with a 1 month trial period
export const placementContractSummaryShort = (jobPost: JobPost, showPostfix: boolean = true) => {
  const placementContract = jobPost.contract?.placement_contract;
  return (
    <>
      Paid {placementContract.disbursement.toLowerCase()} over {placementContract.term}{" "}
      {DISBURSEMENT_SCHEDULE_OPTIONS.find((d) => d.value === placementContract.disbursement)?.human.toLocaleLowerCase()}
      {placementContract.term > 1 ? "s" : ""}
      {placementContract.trial > 0 ? ` with a ${placementContract.trial} month trial period` : ""}
      {showPostfix ? " for successful placement" : ""}
    </>
  );
};

// The this on the connector job post page and application page for workers
// Example: $1,000/month for 3 months.
// Example 2: $1,000/month for 3 months starting 1 month after the candidate’s start date.
export const placementContractWorkerSplitSummary = (jobPost: JobPost, connectorJobPost: ConnectorJobPost) => {
  const placementContract = jobPost.contract?.placement_contract;
  return (
    <>
      {formatCurrencyForDisplay(connectorJobPost.worker_split / placementContract.term, {
        showCurrency: false,
        digits: 2,
        precision: 2,
      })}
      /{DISBURSEMENT_SCHEDULE_OPTIONS.find((ds) => ds.value === placementContract.disbursement)?.human.toLowerCase()}{" "}
      for {placementContract.term} {DISBURSEMENT_SCHEDULE_LABEL_LOOKUP[placementContract.disbursement]?.toLowerCase()}
      {placementContract.term > 1 ? "s" : ""}
      {placementContract.trial > 0
        ? ` starting ${placementContract.trial} ${DISBURSEMENT_SCHEDULE_LABEL_LOOKUP[
            placementContract.disbursement
          ]?.toLowerCase()}${placementContract.trial > 1 ? "s" : ""} after your start date`
        : ""}
      .
    </>
  );
};

// This is on the job post form for employers
// Example 2: When you hire a candidate, each month, you would pay $1,000 to the
// referrer and an additional $250 to Zettlor. The first payment would be sent on the
// candidate’s start date.
// Example: When you hire a candidate, each month, you would pay $1,000 to the
// referrer and an additional $250 to Zettlor. The first payment would be sent 1 month
// after the candidate’s start date.
export const placementContractSummaryEmployer = (
  disbursement: string,
  value: number,
  term: number,
  trial: number,
  hideTooltip?: boolean,
) => {
  const disbursementValue =
    DISBURSEMENT_SCHEDULE_LABEL_LOOKUP[disbursement as keyof typeof DISBURSEMENT_SCHEDULE_LABEL_LOOKUP]?.toLowerCase();
  return (
    <>
      <span>
        When you hire a candidate, <span className="font-600">each {disbursementValue}</span> for{" "}
        <span className="font-600">
          {term} {disbursementValue}
          {term > 1 ? "s" : ""}
        </span>
        , you would pay{" "}
        <span className="font-600">
          {formatCurrencyForDisplay(formatCurrencyForServer(value / term), {
            showCurrency: false,
            digits: 2,
            precision: 2,
          })}
        </span>{" "}
        to the referrer plus the Zettlor fee
        {!hideTooltip && (
          <Tooltip
            content={
              <div>
                The Zettlor fee varies based on the source of the candidate. If the connector is an employee of your
                organization, the fee is{" "}
                {formatCurrencyForDisplay(formatCurrencyForServer(value / term) * 0.049, {
                  showCurrency: false,
                  digits: 2,
                  precision: 2,
                })}{" "}
                per {disbursementValue}. If the connector is external to your organization, the fee is{" "}
                {formatCurrencyForDisplay(formatCurrencyForServer(value / term) * 0.25, {
                  showCurrency: false,
                  digits: 2,
                  precision: 2,
                })}{" "}
                per {disbursementValue} for the duration of the contract.
              </div>
            }
          >
            <QuestionMarkCircleIcon className="ml-1 inline-block h-4 w-4" />
          </Tooltip>
        )}
        .{" "}
        {trial > 0 ? (
          <span>
            The first payment would be sent{" "}
            <span className="font-600">
              {trial} {disbursementValue}
              {trial > 1 ? "s" : ""}
            </span>{" "}
            after the candidate’s start date.
          </span>
        ) : (
          "The first payment would be sent on the candidate’s start date."
        )}{" "}
        If the referred candidate leaves before {disbursementValue} {term}, the payments will stop and you will not be
        charged further.
      </span>
    </>
  );
};

// This is on the make offer form for connectors
// Example 2: When you submit a candidate and they get hired, you will earn $1,000 every month.
// Your first payment will be made on the day the candidate starts their job.
export const placementContractSummaryConnector = (disbursement: SelectOption, value: number, term: number) => {
  const disbursementValue =
    DISBURSEMENT_SCHEDULE_LABEL_LOOKUP[
      disbursement.value as keyof typeof DISBURSEMENT_SCHEDULE_LABEL_LOOKUP
    ]?.toLowerCase();
  return (
    <>
      <span>
        When you submit a candidate and they get hired, you'll receive{" "}
        <span className="font-600">
          {formatCurrencyForDisplay(formatCurrencyForServer(value / term), {
            showCurrency: false,
            digits: 2,
            precision: 2,
          })}{" "}
          every {disbursementValue} for {term} {disbursementValue}
          {term > 1 ? "s" : ""}
        </span>
        . Your first payment will be made on the day the candidate starts their job.
      </span>
    </>
  );
};
