import { BaseLocation } from "./locationBase";
import { BaseOrganization } from "./organizationBase";
import { BaseUser } from "./userBase";

export type JobPostOption = {
  uuid: string;
  name: string;
};

export enum JobPostStatus {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  ARCHIVED = "ARCHIVED",
  CLOSED = "CLOSED",
}

export enum JobPostVisibility {
  PUBLIC = "PUBLIC",
  INTERNAL = "INTERNAL",
  HIDDEN = "HIDDEN",
  PUBLIC_REFERRAL_REWARD = "PUBLIC_REFERRAL_REWARD",
}

export enum CompensationPeriod {
  ANNUALLY = "ANNUALLY",
  HOURLY = "HOURLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum EmploymentType {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  CONTRACTOR = "CONTRACTOR",
  TEMPORARY = "TEMPORARY",
  INTERN = "INTERN",
  OTHER = "OTHER",
}

export enum JobLocationType {
  REMOTE = "REMOTE",
  IN_PERSON = "IN_PERSON",
  HYBRID = "HYBRID",
}

export type BaseJobPost = {
  // Core
  uuid: string;
  short_id: string;
  title: string;
  description: string;
  employment_type: string;

  // Location
  job_location_type: JobLocationType;
  job_location?: BaseLocation;

  // Compensation
  compensation_period: CompensationPeriod;
  compensation_min?: number;
  compensation_max?: number;
  signing_bonus?: number;

  // Status
  status: JobPostStatus;
  visibility: JobPostVisibility;

  // Relationships
  creator: BaseUser;
  organization: BaseOrganization;
  industry: JobPostOption;
  function: JobPostOption;
  benefits: JobPostOption[];
  has_connector_job_posts: boolean;

  // Third party
  remote_posting_url?: string;
  is_remote_posting: boolean;
  show_apply_button: boolean;

  // Timestamps
  published_at?: string;
  created_at: string;
  updated_at: string;
};
