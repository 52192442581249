import classNames from "classnames";
import { FC } from "react";

import { Text } from ".";

interface ErrorMessageProps {
  error?: string;
  className?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error, className }) => (
  <>
    {error && (
      <Text variant="b4" className={classNames("font-medium text-red-500 py-2", className)} weight="font-500">
        {error}
      </Text>
    )}
  </>
);
