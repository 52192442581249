import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FC, Fragment, ReactNode, useEffect } from "react";

import { useKeyPress } from "@/lib/hooks/use-key-press";

interface DialogProps {
  showDialog: boolean;
  onClose?: () => void;
  children: ReactNode;
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl";
  contentClassName?: string;
}

const MAX_WIDTH_LOOKUP = {
  sm: "sm:max-w-lg",
  md: "sm:max-w-xl",
  lg: "sm:max-w-2xl",
  xl: "sm:max-w-3xl",
  "2xl": "sm:max-w-4xl",
};

export const Dialog: FC<DialogProps> = ({ showDialog, onClose, children, maxWidth = "sm", contentClassName }) => {
  const escPress = useKeyPress("Escape");

  useEffect(() => {
    if (escPress && onClose) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escPress]);

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <HeadlessDialog as="div" className="relative z-20" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zettlor-new-black/40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDialog.Panel
                className={`relative w-full overflow-hidden rounded-lg bg-zettlor-new-off-white text-left shadow-xl transition-all ${`${MAX_WIDTH_LOOKUP[maxWidth]}`}`}
              >
                {onClose && (
                  <div className="absolute right-4 top-4 z-10 hover:cursor-pointer" onClick={onClose}>
                    <XMarkIcon className="w-6 stroke-2 text-zettlor-new-black/60" />
                  </div>
                )}
                <div className={classNames(contentClassName, "p-6 sm:p-10")}>{children}</div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};
