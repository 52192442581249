import { captureException } from "@sentry/nextjs";
import posthog from "posthog-js";
import { useEffect } from "react";

import { useApp } from "@/lib/context/app-context";

// import { FEATURE_FLAGS } from "@/lib/feature-flags";

export const usePostHogAnalytics = () => {
  const { user } = useApp();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_POST_HOG_API_KEY && process.env.NEXT_PUBLIC_POST_HOG_HOST) {
      posthog.init(process.env.NEXT_PUBLIC_POST_HOG_API_KEY, {
        api_host: process.env.NEXT_PUBLIC_POST_HOG_HOST,
        bootstrap: {
          featureFlags: {},
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!!user) {
      try {
        posthog.identify(user.uuid, {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          is_employer: user.is_employer,
          is_connector: user.is_connector,
          is_worker: user.is_worker,
          selected_role: user.selected_role,
          organization_ids: user.organizations.map((org) => org.uuid),
          connector_organization_ids: user.connector_organizations.map((org) => org.uuid),
        });
      } catch (e) {
        captureException(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};
