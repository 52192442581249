export enum LedgerTransactionStatus {
  PENDING = "pending",
  POSTED = "posted",
  ARCHIVED = "archived",
}

export type BaseLedgerTransaction = {
  // Core
  uuid: string;
  status: LedgerTransactionStatus;
  description: string;

  // Timestamps
  created_at: string;
  effective_at: string;
};
