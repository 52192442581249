import { useRouter } from "next/router";

import classNames from "classnames";
import { FC, useState } from "react";
import { UserRole } from "types/userRole";

import { Spinner } from "./spinner";

interface AuthSocialButtonProps {
  provider?: {
    label: string;
    value: string;
    getOauthUrl: (_state?: string) => string;
  };
  role?: UserRole;
  sessionLoading: boolean;
  redirect?: string;
  extra?: {
    invitation_uuid?: string;
    job_application_uuid?: string;
    network_member_uuid?: string;
    employer_onboarding_uuid?: string;
    co_incentive_code?: string;
  };
}

export const AuthSocialButton: FC<AuthSocialButtonProps> = ({
  sessionLoading,
  provider,
  role,
  redirect,
  extra = {},
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const disabled = loading || sessionLoading;

  const handleClick = async () => {
    setLoading(true);
    window.location.href = provider.getOauthUrl(
      JSON.stringify({
        redirect: redirect || router.query.redirect || undefined,
        co_incentive_code: router.query.co_incentive_code || extra.co_incentive_code || undefined,
        selected_role: role || undefined,
        ...extra,
      }),
    );
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={classNames(
        "transition-colors delay-50 ease-in-out text-base font-medium leading-none rounded-lg",
        "flex relative w-full items-center border-2 border-zettlor-new-black/20 py-2 px-3 font-medium hover:bg-zettlor-new-black/[0.08] space-x-2",
        disabled ? "cursor-wait opacity-60" : "",
      )}
    >
      <img src={`/social/${provider.value}-icon.svg`} alt={`${provider.label} Logo`} className="h-7 w-7" />
      <p className="flex-1 sm:pr-7">
        <span className="font-520">
          Continue with <span className="capitalize">{provider.label}</span>
        </span>
        {disabled && (
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-50">
            <Spinner size="8" />
          </span>
        )}
      </p>
    </button>
  );
};
