import { BaseJobPost, JobPost } from "types/models";

import { COMPENSATION_PERIOD_LABEL_LOOKUP } from "./constants";

const CURRENCY = "USD";

// 2500000 (float in cents) -> $25,000 USD (string in dollars)
export const formatCurrencyForDisplay = (
  centsValue: number,
  {
    showCurrency = true,
    digits = 0,
    precision = 0,
  }: {
    showCurrency?: boolean;
    digits?: number;
    precision?: number;
  } = {},
) => {
  let num = 0;
  let dollarValue = centsValue / 100;
  if (dollarValue) {
    // Adjusted rounding logic based on precision
    const multiplier = Math.pow(10, precision);
    num = Math.round(dollarValue * multiplier) / multiplier;
  }

  return (
    num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }) + (showCurrency ? ` ${CURRENCY}` : "")
  );
};

// 2500000 (float in cents) -> 25000 (number in dollars)
export const formatCurrencyForInput = (input?: number) => {
  if (!input) return undefined;
  return Math.round(input / 100);
};

// 25,000 (number in dollars) -> 2500000 (float in cents)
export const formatCurrencyForServer = (input: number) => {
  return Math.round(input * 100);
};

interface CompensationObject {
  compensation_min?: number;
  compensation_max?: number;
  compensation_period?: string;
}

export const formatCompensationForDisplay = (jobPost: JobPost | BaseJobPost | CompensationObject): string | null => {
  const { compensation_min, compensation_max, compensation_period } = jobPost;
  const compensationPeriodLabel = COMPENSATION_PERIOD_LABEL_LOOKUP[compensation_period];
  if (compensation_min && compensation_max) {
    return `${formatCurrencyForDisplay(compensation_min, { showCurrency: false })}-${formatCurrencyForDisplay(
      compensation_max,
      { showCurrency: false },
    )} ${compensationPeriodLabel}`;
  } else if (compensation_min) {
    return `${formatCurrencyForDisplay(compensation_min, { showCurrency: false })} ${compensationPeriodLabel}`;
  } else {
    return null;
  }
};
