import { useRouter } from "next/router";

import React, { createContext, useContext, useEffect, useState } from "react";
import { BaseOrganization, SessionUser } from "types/models";
import { User } from "types/models";
import { BaseConnectorOrganization } from "types/models/connectorOrganizationBase";
import { UserRole } from "types/userRole";

import {
  EmployerOnboardingDialog,
  EmployerOnboardingVariant,
} from "components/onboarding/employer-onboarding-dialog/employer-onboarding-dialog";

import { getLocalStorageItem, setLocalStorageItem } from "@/lib/utilities/local-storage";

import ZettlorService from "services/zettlor-service";

type SessionStatus = "authenticated" | "loading" | "unauthenticated";
const LOCAL_STORAGE_ORGANIZATION_SCOPE_KEY = "organizationScope";
const LOCAL_STORAGE_CONNECTOR_ORGANIZATION_SCOPE_KEY = "connectorOrganizationScope";

export interface ContextProps {
  connectorOrganization?: BaseConnectorOrganization;
  organization?: BaseOrganization;
  session: SessionUser;
  sessionStatus: SessionStatus;
  user: User;
  setSession: (_session: SessionUser) => void;
  reloadSession: () => void;
  setOrganizationByUuid: (_organizationUuid: string) => void;
  setConnectorOrganizationByUuid: (_connectorOrganizationUuid: string) => void;
  setUser: (_user: User) => void;
  switchRole: (_role: UserRole) => void;
  handleEmployerOnboardingDialogOpen: (_variant: EmployerOnboardingVariant) => void;
  handleEmployerOnboardingDialogClose: () => void;
}

const AppContext = createContext<ContextProps | null>(null);

export interface AppProviderProps {
  children?: React.ReactNode;
}

const zettlorService = new ZettlorService();
export const AppProvider: React.FC<Readonly<AppProviderProps>> = ({ children }) => {
  const router = useRouter();

  // Session
  const [organization, setOrganization] = useState<BaseOrganization>();
  const [connectorOrganization, setConnectorOrganization] = useState<BaseConnectorOrganization>();
  const [user, setUser] = useState<User>();
  const [session, setSession] = useState<SessionUser>();
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>("loading");

  // Onboarding
  const [showEmployerOnboardingDialog, setShowEmployerOnboardingDialog] = useState(false);
  const [employerOnboardingVariant, setEmployerOnboardingVariant] = useState<EmployerOnboardingVariant>("publish");

  useEffect(() => {
    void loadSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadUser = async () => {
      const { data: userData } = await zettlorService.getUser(session.pk);
      setUser(userData);
      if (userData?.organizations?.length > 0) {
        setInitialOrganization(userData.organizations);
      }
      if (userData?.connector_organizations?.length > 0) {
        setInitialConnectorOrganization(userData.connector_organizations);
      }
    };

    if (session) {
      setSessionStatus("authenticated");
      void loadUser();
    }
  }, [session]);

  const setInitialOrganization = (organizations: BaseOrganization[]) => {
    const curOrgUuidScope = getLocalStorageItem(LOCAL_STORAGE_ORGANIZATION_SCOPE_KEY);
    if (curOrgUuidScope) {
      const curOrgScope = organizations.find((o) => o.uuid === curOrgUuidScope);
      if (curOrgScope) {
        return setOrganization(curOrgScope);
      }
    }
    // Fallback first one
    setOrganization(organizations[0]);
    setLocalStorageItem(LOCAL_STORAGE_ORGANIZATION_SCOPE_KEY, organizations[0].uuid);
  };

  const setInitialConnectorOrganization = (connectorOrganizations: BaseConnectorOrganization[]) => {
    const curConOrgUuidScope = getLocalStorageItem(LOCAL_STORAGE_CONNECTOR_ORGANIZATION_SCOPE_KEY);
    if (curConOrgUuidScope) {
      const curConOrgScope = connectorOrganizations.find((o) => o.uuid === curConOrgUuidScope);
      if (curConOrgScope) {
        return setConnectorOrganization(curConOrgScope);
      }
    }
    // Fallback first one
    setConnectorOrganization(connectorOrganizations[0]);
    setLocalStorageItem(LOCAL_STORAGE_CONNECTOR_ORGANIZATION_SCOPE_KEY, connectorOrganizations[0].uuid);
  };

  const loadSession = async () => {
    const { data: sessionUser } = await zettlorService.getSessionUser();
    if (sessionUser) {
      setSessionStatus("authenticated");
      setSession(sessionUser);
    } else {
      setSessionStatus("unauthenticated");
      clearUser();
    }
  };

  const clearUser = () => {
    setSession(undefined);
    setUser(undefined);
    setOrganization(undefined);
    setConnectorOrganization(undefined);
  };

  const switchRole = async (newRole: UserRole) => {
    await router.push(`/account/switch-role/${newRole}`);
  };

  const handleEmployerOnboardingDialogClose = () => {
    setShowEmployerOnboardingDialog(false);
  };

  const handleEmployerOnboardingDialogOpen = (variant: EmployerOnboardingVariant) => {
    setEmployerOnboardingVariant(variant);
    setShowEmployerOnboardingDialog(true);
  };

  return (
    <AppContext.Provider
      value={{
        organization,
        connectorOrganization,
        session,
        sessionStatus,
        user,
        setSession,
        reloadSession: loadSession,
        setOrganizationByUuid: (organizationUuid: string) => {
          const organization = user.organizations.find((uo) => uo.uuid === organizationUuid);
          setOrganization(organization);
          setLocalStorageItem(LOCAL_STORAGE_ORGANIZATION_SCOPE_KEY, organization.uuid);
        },
        setConnectorOrganizationByUuid: (connectorOrganizationUuid: string) => {
          const connectorOrganization = user.connector_organizations.find(
            (uo) => uo.uuid === connectorOrganizationUuid,
          );
          setConnectorOrganization(connectorOrganization);
          setLocalStorageItem(LOCAL_STORAGE_CONNECTOR_ORGANIZATION_SCOPE_KEY, connectorOrganization.uuid);
        },
        setUser,
        switchRole,
        handleEmployerOnboardingDialogOpen,
        handleEmployerOnboardingDialogClose,
      }}
    >
      {children}
      <EmployerOnboardingDialog
        showDialog={showEmployerOnboardingDialog}
        onClose={handleEmployerOnboardingDialogClose}
        variant={employerOnboardingVariant}
      />
    </AppContext.Provider>
  );
};

export function useApp(): ContextProps {
  const userContext = useContext(AppContext);
  if (!userContext) {
    throw new TypeError("`useApp` must be called from within an `AppProvider`");
  }

  return userContext;
}
