import { useRouter } from "next/router";

import { FC, useEffect, useState } from "react";
import { BaseEmployerOnboarding } from "types/models/employerOnboardingBase";

import { trackEvent } from "components/analytics-wrapper/analytics-wrapper";
import { Dialog } from "components/dialog/dialog";
import { Button, Text } from "components/ui";

import { LOG_IN_URL } from "@/lib/constants";
import { useApp } from "@/lib/context/app-context";

import { OnboardingStep } from "./onboarding-step";

export type EmployerOnboardingVariant = "publish" | "launch";

interface EmployerOnboardingDialogProps {
  showDialog: boolean;
  variant: EmployerOnboardingVariant;
  onClose: () => void;
}

export const EmployerOnboardingDialog: FC<EmployerOnboardingDialogProps> = ({ showDialog, onClose, variant }) => {
  const { handleEmployerOnboardingDialogClose } = useApp();
  const router = useRouter();

  const [employerOnboarding, setEmployerOnboarding] = useState<BaseEmployerOnboarding | undefined>();
  const [onboardingStep, setOnboardingStep] = useState(1);

  useEffect(() => {
    trackEvent("Employer Onboarding Dialog Opened", {});
  }, []);

  useEffect(() => {
    trackEvent("Employer Onboarding Dialog Step Started", {
      step: onboardingStep,
    });
  }, [onboardingStep]);

  useEffect(() => {
    document.body.style.overflow = showDialog ? "hidden" : "";
    if (!showDialog) {
      setOnboardingStep(1);
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [showDialog]);

  const handleSignIn = () => {
    handleEmployerOnboardingDialogClose();
    router.push(LOG_IN_URL);
  };

  return (
    <Dialog
      showDialog={showDialog}
      onClose={onClose}
      contentClassName="!p-0 h-[calc(100svh-32px)] overflow-scroll sm:h-[600px]"
      maxWidth="2xl"
    >
      <div className="sm:flex sm:h-full">
        <div className="w-full shrink-0 justify-between bg-zettlor-new-black/[0.04] p-3 sm:flex sm:h-full sm:w-[260px] sm:flex-col">
          <div className="p-3">
            <Text
              variant="h2"
              className="!text-[26px] !leading-[30px] sm:!text-[32px] sm:!leading-[38px]"
              weight="font-600"
            >
              Welcome to <span className="text-zettlor-new-orange">Zettlor</span>
            </Text>
          </div>
          <div className="block">
            <div className="sm:p-3">
              <Text variant="b4" className="mb-2 hidden text-center sm:block" weight="font-460">
                Already zet up?
              </Text>
              <Button variant="secondary" className="w-full space-x-1" onClick={handleSignIn}>
                <span className="block sm:hidden">Already zet up?</span>
                <span>Sign In</span>
              </Button>
            </div>
          </div>
        </div>
        <OnboardingStep
          onboardingStep={onboardingStep}
          handleOnboardingStepChange={setOnboardingStep}
          employerOnboarding={employerOnboarding}
          setEmployerOnboarding={setEmployerOnboarding}
          variant={variant}
        />
      </div>
    </Dialog>
  );
};
