// https://stackoverflow.com/a/41462752/3689060
const _checkIfStorageAvailable = () => {
  try {
    let storage = window.localStorage;
    let x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const setLocalStorageItem = (key: string, value: any) => {
  if (_checkIfStorageAvailable()) {
    window.localStorage.setItem(key, value);
  } else {
    return null;
  }
};

export const getLocalStorageItem = (key: string) => {
  if (_checkIfStorageAvailable()) {
    return window.localStorage.getItem(key);
  } else {
    return null;
  }
};

export const removeLocalStorageItem = (key: string) => {
  if (_checkIfStorageAvailable()) {
    window.localStorage.removeItem(key);
  } else {
    return null;
  }
};
