import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BaseEmployerOnboarding } from "types/models/employerOnboardingBase";
import { zodSelectOption } from "types/zod-types";
import * as z from "zod";

import { Input } from "components/form-inputs/input";
import { Select, SelectOption } from "components/form-inputs/select";
import { Button, Notice, Text } from "components/ui";

import { DEFAULT_SCHEDULE_OPTIONS } from "@/lib/constants";
import { placementContractSummaryEmployer } from "@/lib/contract-copy";
import { formatCurrencyForInput, formatCurrencyForServer } from "@/lib/currency";
import { ErrorObject } from "@/lib/errors";
import { useScrollToError } from "@/lib/hooks/use-scroll-to-error";

import ZettlorService from "services/zettlor-service";

// TODO schedule validation
const contractSchema = z.object({
  value: z.number().min(100).max(50000),
  schedule: zodSelectOption,
});

type OnboardingContractFormData = {
  value: number;
  schedule: SelectOption & { trial: number; disbursement: string; term: number };
};

interface OnboardingContractFormProps {
  employerOnboarding: BaseEmployerOnboarding;
  onSuccess: () => void;
}

const zettlorService = new ZettlorService();
export const OnboardingContractForm: FC<OnboardingContractFormProps> = ({ employerOnboarding, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState<ErrorObject>();
  const [canFocus, setCanFocus] = useState(true);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<OnboardingContractFormData>({
    shouldFocusError: false,
    resolver: zodResolver(contractSchema),
    defaultValues: {
      schedule: DEFAULT_SCHEDULE_OPTIONS[0],
      value: formatCurrencyForInput(employerOnboarding.contract_value || 600000),
    },
  });

  useScrollToError(errors, canFocus, setCanFocus);

  const onError = () => {
    setCanFocus(true);
  };

  const getDisbursementSchedule = (formData) => {
    const schedule = DEFAULT_SCHEDULE_OPTIONS.find((c) => c.value === formData.schedule.value);
    return {
      contract_disbursement: schedule.disbursement,
      contract_term: schedule.term,
      contract_trial: schedule.trial,
    };
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const { error, success } = await zettlorService.employerOnboardingSetContract(employerOnboarding.uuid, {
      contract_value: formatCurrencyForServer(formData.value),
      ...getDisbursementSchedule(formData),
    });
    if (!success) {
      toast.error("There was an error, please try again or contact support.");
      setLoading(false);
      return setApiErrors(error);
    }

    onSuccess();
  };

  const openChatBot = () => {
    window.$chatwoot.toggle();
  };

  const value = watch("value");
  const schedule = watch("schedule");

  return (
    <div className="relative h-full">
      <form onSubmit={handleSubmit(onSubmit, onError)} className="h-full">
        <div className="px-6 pt-6 sm:px-8 sm:pt-8">
          <Text variant="h5" weight="font-560">
            Referral terms
          </Text>
          <Text variant="b3" className="mb-5 mt-1 text-zettlor-new-black/80" weight="font-460">
            Enter your referral program terms. You can change these terms at any time.
          </Text>
        </div>
        <div className="space-y-5 px-6 pb-8 sm:px-8 sm:pb-0">
          <Notice variant="error">{apiErrors?.fields?.non_field_errors?.[0] || apiErrors?.message}</Notice>

          <div>
            <label className="font-480 mb-1 flex space-x-1 text-[14px] leading-snug text-zettlor-new-black/80">
              Referral bonus terms
              <span className="text-orange-500">*</span>
            </label>
            <div className="sm:flex">
              <Controller
                name="value"
                control={control}
                render={({ field: { name, ref, onChange, value } }) => {
                  return (
                    <Input<OnboardingContractFormData>
                      required
                      preText="$"
                      type="number"
                      placeholder="1,000"
                      wrapperClassName="sm:w-32 w-full"
                      inputWrapperClassName="sm:!rounded-r-none"
                      inputClassName="sm:rounded-r-none sm:border-r-0"
                      value={value}
                      inputRef={ref}
                      name={name}
                      onChange={(e) => onChange(parseInt(e.target.value, 10))}
                      error={errors.value?.message || apiErrors?.fields?.contract_value?.[0]}
                    />
                  );
                }}
              />

              <Controller
                name="schedule"
                control={control}
                render={({ field: { value, onChange, ref } }) => {
                  return (
                    <Select
                      inputRef={ref}
                      wrapperClassName="flex-1 mt-2 sm:mt-0"
                      id="schedule"
                      options={DEFAULT_SCHEDULE_OPTIONS}
                      customStylingConfig={{
                        inlineRight: true,
                      }}
                      placeholder="Select"
                      value={value}
                      required
                      onChange={(option) => {
                        onChange(option);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>

          <Text variant="b2" weight="580" className="text-zettlor-new-black/60 sm:absolute sm:bottom-28 sm:mr-8">
            {placementContractSummaryEmployer(schedule.disbursement, value, schedule.term, schedule.trial, true)}
          </Text>
        </div>
        <div className="sticky bottom-0 flex w-full justify-between border-t border-zettlor-new-black/20 bg-zettlor-new-off-white px-6 py-4 text-center sm:absolute sm:py-5">
          <div className="text-left">
            <Text variant="b4" className="text-zettlor-new-black/60" weight="font-460">
              Have questions? Need help?
              <br />
              <div onClick={openChatBot} className="cursor-pointer text-zettlor-new-black/60 underline">
                Chat with our team.
              </div>
            </Text>
          </div>
          <div>
            <Button type="submit" loading={loading}>
              Generate preview
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
