import classNames from "classnames";
import { FC } from "react";

import { Spinner } from "./spinner";

interface LoaderProps {
  className?: string;
}

export const Loader: FC<LoaderProps> = ({ className }) => {
  return (
    <div className={classNames("flex items-center justify-center m-auto", className)}>
      <Spinner size="12" />
    </div>
  );
};
