import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BaseEmployerOnboarding } from "types/models/employerOnboardingBase";

import { EmployerOnboardingVariant } from "./employer-onboarding-dialog";
import { OnboardingContractForm } from "./onboarding-contract-form";
import { OnboardingFormStep } from "./onboarding-form-step";
import { OnboardingLoadingStep } from "./onboarding-loading-step";

const TransitionWrapper = ({ children, show }) => {
  return (
    <Transition
      as={Fragment}
      show={show}
      enter="transition-opacity duration-100 delay-50"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="h-full w-full sm:absolute">{children}</div>
    </Transition>
  );
};

interface OnboardingStepProps {
  onboardingStep: number;
  handleOnboardingStepChange: (_step: number) => void;
  employerOnboarding?: BaseEmployerOnboarding;
  setEmployerOnboarding?: (_employerOnboarding: BaseEmployerOnboarding) => void;
  variant: EmployerOnboardingVariant;
}

export const OnboardingStep = ({
  onboardingStep,
  handleOnboardingStepChange,
  setEmployerOnboarding,
  employerOnboarding,
  variant,
}: OnboardingStepProps) => {
  return (
    <div className="relative h-full w-full">
      <TransitionWrapper show={onboardingStep === 1}>
        <OnboardingFormStep
          variant={variant}
          onSuccess={(employerOnboarding) => {
            setEmployerOnboarding(employerOnboarding);
            handleOnboardingStepChange(3);
          }}
        />
      </TransitionWrapper>

      <TransitionWrapper show={onboardingStep === 2}>
        <OnboardingContractForm
          employerOnboarding={employerOnboarding}
          onSuccess={() => {
            handleOnboardingStepChange(3);
          }}
        />
      </TransitionWrapper>

      <TransitionWrapper show={onboardingStep === 3}>
        <OnboardingLoadingStep employerOnboarding={employerOnboarding} />
      </TransitionWrapper>
    </div>
  );
};
