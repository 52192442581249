import { useEffect } from "react";

// Currently only used for the job post form
export const useScrollToError = (errors, canFocus, setCanFocus) => {
  useEffect(() => {
    if (errors && canFocus) {
      // Sort inputs based on their position on the page. (the order will be based on validation order otherwise)
      const elements = Object.keys(errors)
        .map((name) => document.querySelectorAll<HTMLInputElement>(`#${name}`)[0])
        .filter((el) => !!el);
      elements.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);

      if (elements.length > 0) {
        let errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari
        errorElement.focus({ preventScroll: true });
        setCanFocus(false); // so the form doesn't suddenly jump to the next input that has error.
      }
    }
  }, [errors, canFocus, setCanFocus]);
};
