import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FC, Fragment } from "react";

export interface StatusOption {
  status: string;
  color: string;
  label: string;
}

interface StatusSelectProps {
  statuses: StatusOption[];
  selectedStatuses: StatusOption[];
  handleSelectedStatusesChange: (_selectedStatuses: StatusOption[]) => void;
}

export const StatusSelect: FC<StatusSelectProps> = ({ statuses, selectedStatuses, handleSelectedStatusesChange }) => {
  return (
    <Listbox value={selectedStatuses} onChange={handleSelectedStatusesChange} multiple by="status">
      <div className="relative">
        <Listbox.Button
          className={classNames(
            "relative min-w-[200px] w-full cursor-pointer border text-left block px-2.5 py-[7px] rounded-lg text-sm placeholder-gray-400 bg-zettlor-new-off-white border-zettlor-new-black/20 appearance-none focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-[#6D82CC]	focus:shadow-none",
          )}
        >
          <span className="flex items-center space-x-2 truncate">
            <span className="text-base leading-tight">Status</span>
            <span className="rounded-lg bg-black px-1.5 text-[10px] text-white">
              {selectedStatuses.length}/{statuses.length}
            </span>
            <div className="flex items-center justify-start space-x-0.5">
              {selectedStatuses.map((sp, i) => (
                <span key={i} className={`inline-block h-[10px] w-[10px] rounded ${sp.color}`}></span>
              ))}
            </div>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-zinc-900" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg border border-zettlor-new-black/20 bg-zettlor-new-off-white p-2 text-base shadow ring-0 ring-black/5 focus:outline-none sm:text-sm">
            {statuses.map((person, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `relative cursor-pointer select-none px-2.5 py-[7px] rounded-lg text-left ${
                    active ? "bg-zettlor-new-black/[0.08]" : ""
                  }`
                }
                value={person}
              >
                {({ selected }) => (
                  <>
                    <span className="flex items-center space-x-2">
                      <span className={`inline-block h-[10px] w-[10px] rounded ${person.color}`}></span>
                      <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                        {person.label}
                      </span>
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 right-2 flex items-center pl-3">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
