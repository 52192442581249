import posthog from "posthog-js";
import React, { createContext, useContext, useEffect, useState } from "react";

import { FEATURE_FLAGS } from "@/lib/feature-flags";

import { useApp } from "./app-context";

// Convert feature flag name to camel case
// e.g. foo-bar => isFooBarEnabled
export const convertFeatureFlagName = (featureFlag: string) => {
  return `is${featureFlag
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("")}Enabled`;
};

const FEATURE_FLAG_KEYS = Object.keys(FEATURE_FLAGS).map((key) => convertFeatureFlagName(FEATURE_FLAGS[key]));

export type FeatureFlagsType = Record<(typeof FEATURE_FLAG_KEYS)[number], boolean>;

interface ContextProps extends FeatureFlagsType {}

const AppContext = createContext<ContextProps | null>(null);

export interface FeatureFlagProviderProps {
  children?: React.ReactNode;
}

export const FeatureFlagProvider: React.FC<Readonly<FeatureFlagProviderProps>> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsType>({});
  const { user } = useApp();

  useEffect(() => {
    const _featureFlags = {};

    posthog.onFeatureFlags(() => {
      Object.keys(FEATURE_FLAGS).forEach((key) => {
        const flag = FEATURE_FLAGS[key];
        _featureFlags[convertFeatureFlagName(flag)] = posthog.isFeatureEnabled(flag);
      });

      setFeatureFlags(_featureFlags);
    });
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        ...featureFlags,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useFeatureFlags = (): ContextProps => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useFeatureFlags must be used within a FeatureFlagProvider");
  }

  return context;
};
