import classNames from "classnames";
import { FC } from "react";

interface Tab {
  label: string;
  href: string;
  current: boolean;
  content: React.ReactNode;
  show: boolean;
  onClick?: () => void;
}

interface TabsProps {
  tabs: Tab[];
}

export const Tabs: FC<TabsProps> = ({ tabs }) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.current)?.label || tabs[0].label}
          onChange={(e) => {
            const selectedTab = tabs.find((tab) => tab.label === e.target.value);
            selectedTab?.onClick?.();
          }}
        >
          {tabs
            .filter((tab) => tab.show)
            .map((tab) => (
              <option key={tab.label}>{tab.label}</option>
            ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-zettlor-new-black/20">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs
              .filter((tab) => tab.show)
              .map((tab) => (
                <button
                  key={tab.label}
                  onClick={tab.onClick}
                  className={classNames(
                    tab.current ? "border-zettlor-new-orange text-zettlor-new-orange" : "border-transparent",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm cursor-pointer font-520",
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.label}
                </button>
              ))}
          </nav>
        </div>
      </div>
      <div className="mt-6">{tabs.find((tab) => tab.current)?.content}</div>
    </div>
  );
};
