import { ShareIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

import { Button } from "components/ui";

import { copyToClipboard } from "@/lib/utilities/copy-to-clipboard";

const share = async (title: string, url: string) => {
  try {
    const shareData = {
      title,
      text: "",
      url,
    };
    await navigator.share(shareData);
  } catch (err) {
    return false;
  }
};

const canShare = (title: string, url: string) => {
  try {
    const shareData = {
      title,
      text: "",
      url,
    };
    return navigator.canShare(shareData);
  } catch (err) {
    return false;
  }
};

interface ShareButtonProps {
  object: string;
  title: string;
  url: string;
  text: string;
  variant: "primary" | "link" | "secondary";
  className?: string;
}

export const ShareButton: FC<ShareButtonProps> = ({ object, title, url, text, variant, className = "" }) => {
  return (
    <Button
      variant={variant}
      onClick={() => (canShare(title, url) ? share(title, url) : copyToClipboard(object, url))}
      className={className}
    >
      <span className="flex items-center space-x-2">
        <ShareIcon className="h-5 w-5" />
        <span>{text}</span>
      </span>
    </Button>
  );
};
