import Image from "next/image";

import classNames from "classnames";
import { FC } from "react";

import { DEFAULT_USER_PROFILE_IMAGE } from "@/lib/constants";

interface ProfileImageProps {
  src?: string;
  alt: string;
  size?: "5" | "6" | "8" | "10" | "12" | "16" | "20" | "32";
  className?: string;
}

const sizeLookup = {
  "5": "h-5 w-5",
  "6": "h-6 w-6",
  "8": "h-8 w-8",
  "10": "h-10 w-10",
  "12": "h-12 w-12",
  "16": "h-16 w-16",
  "20": "h-20 w-20",
  "32": "h-32 w-32",
};

export const ProfileImage: FC<ProfileImageProps> = ({ src, alt, size = 8, className }) => (
  <div
    className={`relative shrink-0 overflow-hidden rounded-full border border-zettlor-new-black/20 duration-200 ease-in-out ${sizeLookup[size]}`}
  >
    {!!src ? (
      <Image className={classNames("w-full h-full", className)} src={src} alt={alt} fill />
    ) : (
      <img
        className={classNames("w-full h-full", className)}
        src={DEFAULT_USER_PROFILE_IMAGE}
        alt={alt}
        style={{ objectFit: "cover" }}
      />
    )}
  </div>
);
