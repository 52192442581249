import classNames from "classnames";
import React, { FC, ReactNode } from "react";

import { Text } from ".";
import { Pagination } from "./pagination";

interface Header {
  key: string;
  content: string | ReactNode;
  className?: string;
}

interface Data {
  [key: string]: string | ReactNode;
}

interface TableProps {
  headers: Header[];
  data: Data[];
  paginationCount?: number;
  paginationPage?: number;
  paginationScrollToTop?: boolean;
  refetchData?: (_page: number) => void;
}

export const Table: FC<TableProps> = ({
  headers,
  data,
  paginationCount,
  paginationPage,
  paginationScrollToTop,
  refetchData,
}) => {
  const handlePrevious = () => {
    const newPage = paginationPage - 1;
    refetchData(newPage);
  };

  const handleNext = () => {
    const newPage = paginationPage + 1;
    refetchData(newPage);
  };

  const showPagination = paginationCount && paginationPage && refetchData;
  return (
    <>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <table className="hidden min-w-full overflow-visible md:table">
              <thead>
                <tr>
                  {headers.map((h, i) => (
                    <th
                      key={`header-col-${i}`}
                      id={`header-col-${i}`}
                      scope="col"
                      className={classNames(
                        "bg-zettlor-new-black/[0.08] py-4 pl-5 pr-4 text-left first:rounded-l-lg last:rounded-r-lg",
                        h.className,
                      )}
                    >
                      <Text variant="h6">{h.content}</Text>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((d, i) => (
                  <tr
                    key={`data-row-${i}`}
                    className={classNames(i > 0 ? "border-t border-zettlor-new-black/[0.04]" : "")}
                  >
                    {headers.map((h, j) => (
                      <td
                        key={`row-col-${j}-${i}`}
                        className={classNames("whitespace-nowrap py-4 pl-5 pr-4", h.className)}
                      >
                        {h.className && h.className.includes("max-w") ? (
                          <div className="truncate">{d[h.key]}</div>
                        ) : (
                          d[h.key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="block min-w-full md:hidden">
              {data.map((d, i) => (
                <div
                  key={`mobile-data-row-${i}`}
                  className={classNames("py-6", i > 0 ? "border-t border-zettlor-new-black/[0.04]" : "")}
                >
                  {headers.map((h, j) => (
                    <div className="grid grid-cols-3 items-center" key={`${h.key}-${j}`}>
                      <div className="col-span-1">{h.content}</div>
                      <div key={`mobile-row-col-${j}-${i}`} className="col-span-2 whitespace-nowrap py-1 pl-2">
                        {d[h.key]}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showPagination && (
        <Pagination
          page={paginationPage}
          count={paginationCount}
          onPrevious={handlePrevious}
          onNext={handleNext}
          scrollToTop={paginationScrollToTop}
        />
      )}
    </>
  );
};
