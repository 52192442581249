import { JobPost } from "./jobPost";
import { BaseOrganization } from "./organizationBase";

export enum EmployerOnboardingStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export type BaseEmployerOnboarding = {
  // Core
  uuid: string;
  status: string;
  job_post_enrichment_status: string;
  organization_enriched_status: string;
  // User details
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  user_phone_number: string;
  // Organization details
  organization_name: string;
  organization_logo: string;
  organization_tagline: string;
  organization_industry: string;
  organization_facebook: string;
  organization_linkedin: string;
  organization_twitter: string;
  organization_website: string;
  organization_instagram: string;
  // Job details
  job_post_url: string;
  job_title: string;
  job_description: string;
  job_employment_type: string;
  job_location_type: string;
  job_compensation_min: number;
  job_compensation_max: number;
  job_compensation_period: string;
  job_signing_bonus: number;
  job_shifts: string;
  job_min_education_required: string;
  job_min_years_experience_required: string;
  // Error details
  job_post_enrichment_error: string;
  organization_enrichment_error: string;
  // Contract details
  contract_value: number;
  contract_disbursement: string;
  contract_term: number;
  contract_trial: number;
  // Relationships
  job_post?: JobPost;
  organization?: BaseOrganization;
  // Timestamps
  created_at: string;
  updated_at: string;
};
