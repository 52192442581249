// import Script from "next/script";
import posthog from "posthog-js";
import { BaseOrganization, Organization } from "types/models";

import { useChatwoot } from "@/lib/hooks/use-chatwoot";
import { usePostHogAnalytics } from "@/lib/hooks/use-post-hog-analytics";
import { useSentry } from "@/lib/hooks/use-sentry";

export const AnalyticsWrapper = () => {
  usePostHogAnalytics();
  useChatwoot();

  useSentry();

  return (
    <>
      {/* Global Site Code Pixel - Facebook Pixel */}
      {/* <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
        `,
        }}
      /> */}
    </>
  );
};

export const trackEvent = (name: string, properties: any, organization?: Organization | BaseOrganization) => {
  try {
    if (organization) {
      posthog.group("organization", organization.uuid, {
        name: organization.name,
      });
    }
    posthog.capture(name, properties);
  } catch {}
};
