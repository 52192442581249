import React, { FC } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { ErrorMessage } from "components/ui";

import { customStyles, customTheme } from "./select";

interface GooglePlacesInputProps {
  handleChange: (_value: any) => void;
  value: any;
  id: string;
  error?: string;
  placeholder?: string;
  label?: string;
  onlyCities?: boolean;
}

export const GooglePlacesInput: FC<GooglePlacesInputProps> = ({
  handleChange,
  value,
  id,
  error,
  placeholder,
  label,
  onlyCities,
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={id} className="font-480 mb-1 flex space-x-1 text-[14px] leading-snug text-zettlor-new-black/80">
          {label}
        </label>
      )}
      <GooglePlacesAutocomplete
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
        autocompletionRequest={{
          types: onlyCities ? ["(cities)"] : undefined,
          componentRestrictions: {
            country: ["us"],
          },
        }}
        debounce={600}
        minLengthAutocomplete={3}
        selectProps={{
          value,
          onChange: (v) => {
            handleChange(v);
          },
          styles: customStyles({ searchCursorMargin: 0 }),
          theme: customTheme,
          className: !!error ? "error" : "",
          placeholder,
          components: {
            IndicatorSeparator: () => null,
          },
        }}
      />
      <ErrorMessage error={error} />
    </div>
  );
};
