import classNames from "classnames";
import { FC } from "react";

import { ToggleBox } from ".";

interface ToggleGroupProps {
  items: {
    label: string;
    content: React.ReactNode;
  }[];
}

export const ToggleGroup: FC<ToggleGroupProps> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <ToggleBox
          key={index}
          label={item.label}
          className={classNames(index > 0 && index < items.length ? "!border-t-0" : "")}
        >
          {item.content}
        </ToggleBox>
      ))}
    </>
  );
};
