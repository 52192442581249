import { UserRole } from "types/userRole";

// =================
// URLs
// =================
export const LOG_IN_URL = "/auth/login";
export const SIGN_UP_URL = "/auth/signup";
export const RESET_PASSWORD_URL = "/auth/reset-password";
export const OAUTH_CALLBACK_URL = "/auth/oauth-callback";
export const ONBOARDING_URL = "/onboarding";
export const PROFILE_URL = "/account/profile";
export const AI_JOB_DESCRIPTION_GENERATOR_URL = "/ai/generate-job-description";
export const AI_MINIMAL_JOB_DESCRIPTION_GENERATOR_URL = "/ai/generate-job-description/minimal";
export const EMPLOYER_DEMO_URL = "/employer-demo";
export const SUBSCRIPTIONS_URL = "/onboarding/subscriptions";

export const WORKER_SCREENING_CAL_URL = "https://cal.com/team/zettlor/candidate-concierge";
export const CONNECTOR_VERIFICATION_CALL_URL = "https://cal.com/team/zettlor/connector-verification";
export const EMPLOYER_DEMO_CAL_URL = "https://cal.com/zettlor/demo-scheduler";

export const TYPEFORM_SUBSCRIBE_URL = "https://zettlor.typeform.com/sign-up";

export const ZELDA_SIGN_UP_URL = "/zelda";
export const ZELDA_SIGN_UP_SUCCESS_URL = "/zelda/success";

// =================
// Media
// =================
export const SEO_IMG_DEFAULT = `${process.env.NEXT_PUBLIC_BASE_URL}/static/og-image-orange.png`;
export const SEO_IMG_OGIMG = `${process.env.NEXT_PUBLIC_BASE_URL}/api/social/og/image`;

export const DEFAULT_USER_PROFILE_IMAGE = `${process.env.NEXT_PUBLIC_BASE_URL}/static/default-user-profile-image.png`;
export const DEFAULT_ORGANIZATION_LOGO = `${process.env.NEXT_PUBLIC_BASE_URL}/static/default-organization-logo.png`;

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB limit
export const MAX_FILE_SIZE_HUMAN_READABLE = `${Math.round(MAX_FILE_SIZE / 1000000)}MB`; // "5MB"
export const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
export const ACCEPTED_DOCUMENT_TYPES = ["application/pdf"];
export const ACCEPTED_IMPORT_FILE_TYPES = [".csv", "text/csv"];

// =================
// Dropdown options
// =================
export const DEFAULT_SCHEDULE_OPTIONS = [
  { label: "Single payment 90 days after hire", value: "90_1", disbursement: "MONTHLY", term: 1, trial: 3 },
  { label: "Single payment 45 days after hire", value: "45_1", disbursement: "WEEKLY", term: 1, trial: 6 },
  { label: "Single payment on start day", value: "1_1", disbursement: "DAILY", term: 1, trial: 0 },
  {
    label: "One payment each month for 12 months after hire",
    value: "12_12",
    disbursement: "MONTHLY",
    term: 12,
    trial: 0,
  },
];

export const DISBURSEMENT_SCHEDULE_OPTIONS = [
  { label: "Every day", value: "DAILY", human: "Day" },
  { label: "Every week", value: "WEEKLY", human: "Week" },
  { label: "Every month", value: "MONTHLY", human: "Month" },
  { label: "Every quarter", value: "QUARTERLY", human: "Quarter" },
  { label: "Every year", value: "ANNUALLY", human: "Year" },
];

export const JOB_POST_VISIBILITY_OPTIONS = [
  {
    label: "Public + Referral Reward (add a financial incentive for current employees, alumni and recruiters)",
    value: "PUBLIC_REFERRAL_REWARD",
  },
  { label: "Public (everyone can see this job post)", value: "PUBLIC" },
  { label: "Internal (only your active employees can see this job post)", value: "INTERNAL" },
  { label: "Unlisted (only those with the job post link can see it)", value: "HIDDEN" },
];

export const CONNECTOR_JOB_POST_VISIBILITY_OPTIONS = [
  { label: "Public (visible on your profile for everyone to see)", value: "PUBLIC" },
  { label: "Unlisted (only those with the job post link can see it)", value: "HIDDEN" },
];

export const COMPENSATION_PERIOD_OPTIONS = [
  { label: "per year", value: "ANNUALLY" },
  { label: "per month", value: "MONTHLY" },
  { label: "per week", value: "WEEKLY" },
  { label: "per day", value: "DAILY" },
  { label: "per hour", value: "HOURLY" },
];

export const COMPENSATION_RANGE_OPTIONS = [
  { label: "Range", value: "range" },
  { label: "Exact value", value: "exact" },
];

export const COMPANY_SIZE_OPTIONS = [
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "51-200", value: "51-200" },
  { label: "201-500", value: "201-500" },
  { label: "501-1000", value: "501-1000" },
  { label: "1001-5000", value: "1001-5000" },
  { label: "5001-10,000", value: "10,000" },
  { label: "10,001+", value: "10,001+" },
];

export const JOB_LOCATION_TYPE_OPTIONS = [
  { label: "Remote", value: "REMOTE" },
  { label: "In Person", value: "IN_PERSON" },
  { label: "Hybrid", value: "HYBRID" },
];

export const JOB_APPLICATION_REJECT_REASON_OPTIONS = [
  { label: "No resume", value: "NO_RESUME" },
  { label: "Not qualified", value: "NOT_QUALIFIED" },
  { label: "Not enough information", value: "NOT_ENOUGH_INFO" },
  { label: "Incorrect location", value: "INCORRECT_LOCATION" },
  { label: "Other", value: "OTHER" },
];

export const LOCATION_TYPE_PREFERENCE_OPTIONS = [
  { label: "Remote", value: "REMOTE" },
  { label: "In Person", value: "IN_PERSON" },
  { label: "Hybrid", value: "HYBRID" },
  { label: "In The Field", value: "IN_THE_FIELD" },
  { label: "On The Road", value: "ON_THE_ROAD" },
];

// =================
// Readable lookups
// =================

export const EMPLOYMENT_TYPE_LOOKUP = {
  FULL_TIME: "Full-time",
  PART_TIME: "Part-time",
  CONTRACTOR: "Contractor",
  TEMPORARY: "Temporary",
  INTERN: "Intern",
  OTHER: "Other",
};

export const DISBURSEMENT_SCHEDULE_LABEL_LOOKUP = {
  DAILY: "Day",
  WEEKLY: "Week",
  MONTHLY: "Month",
  QUARTERLY: "Quarter",
  ANNUALLY: "Year",
};

export const COMPENSATION_PERIOD_LABEL_LOOKUP = {
  ANNUALLY: "a year",
  MONTHLY: "a month",
  WEEKLY: "a week",
  DAILY: "a day",
  HOURLY: "an hour",
};

export const USER_ROLE_DISPLAY_LOOKUP = {
  WORKER: "Candidate",
  CONNECTOR: "Connector",
  EMPLOYER: "Employer",
};

// =================
// Validation
// =================
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const US_PHONE_REGEX =
  /^((\d{3})[ ]?(\d{3})[- ]?(\d{4}))|((\d{3})[ ]?(\d{3})[- ]?(\d{4}))|([(](\d{3})[)][ ]?(\d{3})[- ]?(\d{4}))$/;

// =================
// Notifications
// =================
export const NOTIFICATION_CHANNEL_TYPES = ["email"];
const ALL_ROLE_NOTIFICATION_CATEGORIES = ["account", "payments"]; // "newsletter"
export const NOTIFICATION_CATEGORIES = {
  CONNECTOR: ALL_ROLE_NOTIFICATION_CATEGORIES.concat(["job-posts", "candidates", "job-alerts"]), // "talent-network"
  EMPLOYER: ALL_ROLE_NOTIFICATION_CATEGORIES.concat(["job-posts", "candidates"]),
  WORKER: ALL_ROLE_NOTIFICATION_CATEGORIES.concat(["job-applications"]),
};

export const NOTIFICATION_CATEGORY_DESCRIPTIONS = {
  account: "Get notified about changes to your account and team.",
  "job-posts": "Get notified about events related to your job posts.",
  candidates: "Stay up to date about candidates applying to your roles.",
  "job-alerts": "Get alerted when new jobs are posted.",
  payments: "Be notified when about your payments and payouts.",
  newsletter: "Learn about new features, hiring practices and more.",
  "talent-network": "Talent network related notifications.",
  "job-applications": "Stay up to date on your job applications.",
};

// =================
// Local storage
// =================
export const LOCAL_STORAGE_CO_INCENTIVE_CODE = "zettlor-co-incentive-code";
export const LOCAL_STORAGE_HOW_IT_WORKS_HIDE_KEY = "zettlor-how-it-works-toggle";
export const LOCAL_STORAGE_NETWORK_MEMBER_UUID = "zettlor-network-member-uuid";

// =================
// Contracts
// =================
export const MAX_TERM_LOOKUP = {
  DAILY: 730,
  WEEKLY: 104,
  MONTHLY: 24,
  QUARTERLY: 8,
  ANNUALLY: 2,
};
// =================
// Misc
// =================
export const AUTH_SESSION_COOKIE = "jwt-auth";
export const AUTH_REFRESH_TOKEN_COOKIE = "jwt-auth-refresh";

const GOOGLE_OAUTH_REDIRECT_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/auth/callback/google`;
const LINKEDIN_OAUTH_REDIRECT_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/auth/callback/linkedin`;
export const SUPPORTED_OAUTH_PROVIDERS = [
  {
    value: "google",
    label: "Google",
    getOauthUrl: (state: string = "") =>
      `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${GOOGLE_OAUTH_REDIRECT_URL}&prompt=consent&response_type=code&client_id=${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}&scope=openid%20email%20profile&access_type=offline&state=${state}`,
  },
  {
    value: "linkedin",
    label: "LinkedIn",
    getOauthUrl: (state: string = "") =>
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID}&redirect_uri=${LINKEDIN_OAUTH_REDIRECT_URL}&scope=r_liteprofile%20r_emailaddress&state=${state}`,
  },
];

export const USER_ROLES = Object.values(UserRole);
export const WORKER_ROLE_DESCRIPTION = "Find and apply to jobs";
export const CONNECTOR_ROLE_DESCRIPTION = "Source candidates and get paid";
export const EMPLOYER_ROLE_DESCRIPTION = "Post jobs and get candidates";

export const SUPPORT_EMAIL = "team@zettlor.com";

export const INVITATION_IMPORT_TEMPLATE_URL =
  "https://docs.google.com/spreadsheets/d/1yYWzSNDRxFoJ6PMQPVtELhmOiLXV3JKvQ3CNdPe8lCs";
export const NETWORK_IMPORT_TEMPLATE_URL =
  "https://docs.google.com/spreadsheets/d/1kONqGC2r278lHIP4KW3zkSdx_wytgIqUzWF225bY4G4";

// =================
// Sitemaps
// =================

// The number of JDs to include in each sitemap file.
// This number needs to be small enough so that
// (1) It's less than the 50k that google allows
// (2) The query to sanity doesn't time out
export const SANITY_CHUNK_SIZE = 20000;

// The number of job-posts to include in each sitemap file.
// This number needs to be small enough so that
// (1) It's less than the 50k that google allows
// (2) It's less than 50MB that google allows (recommends)
export const JOB_POSTS_SITEMAP_CHUNK_SIZE = 5000;
