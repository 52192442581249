import { useRouter } from "next/router";

import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { BaseEmployerOnboarding, EmployerOnboardingStatus } from "types/models/employerOnboardingBase";

import { Text } from "components/ui";

import { useApp } from "@/lib/context/app-context";

import ZettlorService from "services/zettlor-service";

interface ProgressBarProps {
  isDone: boolean;
  variant?: "long" | "normal";
}

const ProgressBar = ({ isDone, variant = "normal" }: ProgressBarProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const randomIncrement = Math.random() * (variant === "long" ? 6 : 12);
        const newProgress = prevProgress + randomIncrement > 99 ? 99 : prevProgress + randomIncrement;
        return newProgress;
      });

      if (progress >= 99) clearInterval(interval);
    }, 750);

    return () => clearInterval(interval);
  }, [progress]);

  useEffect(() => {
    if (isDone) {
      setProgress(98);
    }
  }, [isDone]);

  return (
    <div className="mx-auto mt-24 w-full max-w-2xl">
      <Text variant="h3" className="mb-6 text-center">
        Generating your preview ...
      </Text>
      <div className="mx-auto h-8 max-w-[300px] overflow-hidden rounded-full border-2 border-zettlor-new-black/80 bg-white">
        <div className="progress-transition h-8 bg-zettlor-new-orange" style={{ width: `${progress}%` }}></div>
      </div>
      <Text variant="h2" className="mt-4 text-center">
        {progress.toFixed(0)}%
      </Text>
    </div>
  );
};

const zettlorService = new ZettlorService();
interface OnboardingLoadingStepProps {
  employerOnboarding: BaseEmployerOnboarding;
}

export const OnboardingLoadingStep = ({
  employerOnboarding: initialEmployerOnboarding,
}: OnboardingLoadingStepProps) => {
  const router = useRouter();
  const { handleEmployerOnboardingDialogClose } = useApp();

  const [isDone, setIsDone] = useState(
    initialEmployerOnboarding.job_post_enrichment_status === EmployerOnboardingStatus.COMPLETED,
  );
  const [delayedIsDone, setDelayedIsDone] = useState(
    initialEmployerOnboarding.job_post_enrichment_status === EmployerOnboardingStatus.COMPLETED,
  );

  const { data: employerOnboardingData } = useQuery({
    queryKey: ["employerOnboardingPolling", initialEmployerOnboarding.uuid],
    queryFn: () => zettlorService.getEmployerOnboarding(initialEmployerOnboarding.uuid),
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    initialData: { data: initialEmployerOnboarding, success: true },
    enabled: !isDone,
  });
  const employerOnboarding = employerOnboardingData?.data;

  useEffect(() => {
    if (!isDone && employerOnboarding?.job_post_enrichment_status === EmployerOnboardingStatus.COMPLETED) {
      setIsDone(true);
      setTimeout(() => {
        setDelayedIsDone(true);
      }, 1500);
    }
  }, [employerOnboarding]);

  useEffect(() => {
    if (delayedIsDone) {
      handleEmployerOnboardingDialogClose();
      router.push(`/onboarding/${employerOnboarding?.uuid}/preview`);
    }
  }, [delayedIsDone]);

  return (
    <div className="mt-10 flex h-full justify-center">
      <ProgressBar isDone={isDone} variant={!!employerOnboarding?.job_post_url ? "long" : "normal"} />
    </div>
  );
};
