/* eslint-disable unused-imports/no-unused-vars */
import { captureEvent } from "@sentry/nextjs";

export const pageview = () => {
  try {
    window.fbq("track", "PageView");
  } catch (error) {
    captureEvent(error);
  }
};

export enum FbPixelEventType {
  CompleteRegistration = "CompleteRegistration", // Signs up (any role)
  Lead = "Lead", // Signup up as employer (redirect to demo page) (employer)
  Search = "Search", // Search (any role)
  ViewContent = "ViewContent", // View Job (any role)
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: FbPixelEventType, options = {}) => {
  try {
    window.fbq("track", name, options);
  } catch (error) {
    captureEvent(error);
  }
};

export enum FbPixelCustomEventType {
  CreateConnectorJobPost = "CreateConnectorJobPost",
  CreateJobPost = "CreateJobPost",
  CreateJobApplication = "CreateJobApplication",
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const customEvent = (name: FbPixelCustomEventType, options = {}) => {
  try {
    window.fbq("trackCustom", name, options);
  } catch (error) {
    captureEvent(error);
  }
};
