import classNames from "classnames";
import { ChangeEvent } from "react";
import { Path, UseFormRegister } from "react-hook-form";

interface CheckboxProps<TFormValues> {
  name: Path<TFormValues>;
  register?: UseFormRegister<TFormValues>;
  label: string;
  description?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (_e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

export const Checkbox = <TFormValues extends Record<string, unknown>>({
  name,
  label,
  description,
  defaultChecked = undefined,
  disabled = false,
  register,
  onChange,
  checked,
}: CheckboxProps<TFormValues>) => {
  return (
    <div className="flex items-start">
      <div className="flex h-5 items-center">
        <input
          {...(register ? register(name) : {})}
          {...(onChange && !register ? { onChange, checked } : {})}
          id={name}
          name={name}
          type="checkbox"
          className={classNames(
            "mt-1 h-4 w-4 border-zettlor-new-black/20 bg-zettlor-new-off-white rounded",
            disabled
              ? "text-zettlor-gray-600 hover:cursor-not-allowed"
              : "focus:ring-zettlor-new-orange text-zettlor-new-orange",
          )}
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
      </div>
      <div className="ml-2 text-base">
        <label htmlFor={name} className="text-base font-normal">
          {label}
        </label>
        {description && <p className="mt-1 text-zettlor-gray-500">{description}</p>}
      </div>
    </div>
  );
};
