import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import { useApp } from "@/lib/context/app-context";

export const useSentry = () => {
  const { user } = useApp();

  useEffect(() => {
    if (!!user) {
      try {
        Sentry.setUser({
          id: user.uuid,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          is_employer: user.is_employer,
          is_connector: user.is_connector,
          is_worker: user.is_worker,
          selected_role: user.selected_role,
        });
      } catch {}
    }
  }, [user]);
};
