import { FC } from "react";

import { Button } from ".";

interface PaginationProps {
  page?: number;
  count: number;
  paginationPageSize?: number;
  onPrevious: () => void;
  onNext: () => void;
  scrollToTop?: boolean;
}

export const PAGINATION_PAGE_SIZE = 20;

export const Pagination: FC<PaginationProps> = ({
  page = 0,
  count,
  paginationPageSize = PAGINATION_PAGE_SIZE,
  onNext,
  onPrevious,
  scrollToTop = true,
}) => {
  if (count <= paginationPageSize) return <></>;
  return (
    <div className="m-auto mt-10 flex max-w-sm items-center justify-center space-x-5">
      <Button
        onClick={() => {
          if (scrollToTop) window.scrollTo(0, 0);
          onPrevious();
        }}
        disabled={page === 1}
        variant="secondary"
      >
        Previous
      </Button>
      <div>
        {page} of {Math.ceil(count / paginationPageSize)}
      </div>
      <Button
        onClick={() => {
          if (scrollToTop) window.scrollTo(0, 0);
          onNext();
        }}
        disabled={page === Math.ceil(count / paginationPageSize)}
        variant="secondary"
      >
        Next
      </Button>
    </div>
  );
};
