import classNames from "classnames";
import React, { FC, ReactNode } from "react";

interface TextProps {
  children: ReactNode;
  variant: keyof typeof VARIANT_LOOKUP;
  weight?: string;
  className?: string;
}

const VARIANT_LOOKUP = {
  h1xl: {
    className: "text-4xl sm:text-7xl font-serif font-['Clash Display'] leading-[1.12]",
    tag: "h1",
    defaultWeight: "font-700",
  },
  h1: {
    className: "text-4xl sm:text-[50px] font-serif font-['Clash Display'] leading-[1.1]",
    tag: "h1",
    defaultWeight: "font-700",
  },
  h2: {
    className: "text-2xl sm:text-[40px] font-serif font-['Clash Display']",
    tag: "h2",
    defaultWeight: "font-700",
  },
  h3: {
    className: "text-[32px] font-['Clash Grotesk'] leading-[1.2]",
    tag: "h3",
    defaultWeight: "font-520",
  },
  h4: {
    className: "text-lg sm:text-2xl font-['Clash Grotesk'] leading-[1.48]",
    tag: "h4",
    defaultWeight: "font-520",
  },
  h5: {
    className: "text-xl font-['Clash Grotesk'] leading-[1.32]",
    tag: "h5",
    defaultWeight: "font-520",
  },
  h6: {
    className: "text-base font-['Clash Grotesk'] leading-[1.39]",
    tag: "h6",
    defaultWeight: "font-520",
  },
  b1: {
    className: "text-lg sm:text-xl font-['Clash Grotesk'] leading-[1.5]",
    tag: "p",
    defaultWeight: "font-480",
  },
  b2: {
    className: "text-base sm:text-lg font-['Clash Grotesk'] leading-[1.4]",
    tag: "p",
    defaultWeight: "font-480",
  },
  b3: {
    className: "text-base font-['Clash Grotesk'] leading-[1.4]",
    tag: "p",
    defaultWeight: "font-480",
  },
  b4: {
    className: "text-[14px] font-['Clash Grotesk'] leading-[1.4]",
    tag: "p",
    defaultWeight: "font-480",
  },
};

export const Text: FC<TextProps> = ({ variant, children, className = "", weight }) => {
  const element = React.createElement(
    VARIANT_LOOKUP[variant].tag,
    {
      className: classNames(
        VARIANT_LOOKUP[variant].className,
        className,
        weight ? weight : VARIANT_LOOKUP[variant].defaultWeight,
      ),
    },
    children,
  );

  return element;
};
