import { z } from "zod";

const freeEmailDomains = [
  // Globally Popular Free Email Providers
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "live.com",
  "aol.com",
  "mail.com",
  "yandex.com",
  "zoho.com",
  "protonmail.com",

  // Country-Specific Providers
  "gmx.de",
  "web.de", // Germany
  "orange.fr",
  "free.fr", // France
  "mail.ru",
  "bk.ru",
  "inbox.ru",
  "list.ru", // Russia
  "rediffmail.com", // India
  "naver.com",
  "daum.net",
  "hanmail.net", // South Korea
  "qq.com",
  "163.com",
  "126.com",
  "sina.com",
  "sohu.com", // China
  "libero.it",
  "virgilio.it",
  "tiscali.it", // Italy
  "uol.com.br",
  "bol.com.br", // Brazil

  // Educational Institutions
  "edu",
  "ac.uk",
  "edu.au",
  "edu.in", // Various countries

  // Other Generic Providers
  "icloud.com", // Apple
  "fastmail.com", // Fastmail

  // Temporary Email Services
  "mailinator.com",
  "guerrillamail.com",
  "10minutemail.com", // Various
];

const isWorkEmail = (email) => {
  const domain = email.split("@")[1];
  return !freeEmailDomains.includes(domain);
};

export const workEmailSchema = z
  .string()
  .email()
  .refine((email) => isWorkEmail(email), {
    message: "Please enter your work email.",
  });
