import { BaseDocument } from "./documentBase";
import { BaseUser } from "./userBase";

export enum JobApplicationStatus {
  INVITED = "INVITED",
  SOURCED = "SOURCED",
  SUBMITTED = "SUBMITTED",
  ACCEPTED = "ACCEPTED",
  ARCHIVED = "ARCHIVED",
  WITHDRAWN = "WITHDRAWN",
  REJECTED = "REJECTED",
  HIRED = "HIRED",
  EXPIRED = "EXPIRED",
}

export enum JobApplicationVariant {
  DIRECT = "DIRECT",
  INVITE = "INVITE",
  SUBMIT = "SUBMIT",
}

export enum JobApplicationRejectReason {
  NO_RESUME = "NO_RESUME",
  NOT_QUALIFIED = "NOT_QUALIFIED",
  NOT_ENOUGH_INFO = "NOT_ENOUGH_INFO",
  INCORRECT_LOCATION = "INCORRECT_LOCATION",
  OTHER = "OTHER",
}

export type BaseJobApplication = {
  // Core
  uuid: string;
  status: JobApplicationStatus;
  variant: JobApplicationVariant;
  read_receipt?: string;

  // Feedback
  reject_reason?: JobApplicationRejectReason;
  reject_feedback?: string;
  is_qualified?: boolean;

  // Relationships
  user?: BaseUser;
  documents?: BaseDocument[];

  // Timestamps
  created_at: string;
  updated_at: string;
};
