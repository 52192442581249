import { BaseUser } from "./userBase";

export enum ConnectorJobPostStatus {
  OFFERED = "OFFERED",
  COUNTERED = "COUNTERED",
  REJECTED = "REJECTED",
  OPEN = "OPEN",
  ARCHIVED = "ARCHIVED",
}

export enum ConnectorJobPostVisibility {
  PUBLIC = "PUBLIC",
  HIDDEN = "HIDDEN",
}

export type BaseConnectorJobPost = {
  // Core
  uuid: string;
  short_id: string;
  worker_split: number;
  recommendation?: string;

  // Status
  status: ConnectorJobPostStatus;
  visibility: ConnectorJobPostVisibility;

  // Relationships
  connector: BaseUser;
  has_job_applications: boolean;

  // Timestamps
  created_at: string;
  updated_at: string;
};
