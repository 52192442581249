import { XMarkIcon } from "@heroicons/react/24/outline";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import classNames from "classnames";
import React, { FC } from "react";

interface TooltipProps {
  children: React.ReactNode;
  content?: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left" | undefined;
  wrapperClassName?: string;
  contentClassName?: string;
  variant?: "dark" | "light";
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  wrapperClassName,
  contentClassName,
  variant = "light",
  ...props
}) => {
  const contentClassNames = classNames(
    variant === "dark"
      ? "font-480 tooltip-content max-w-[300px] select-none break-normal border-none bg-zettlor-new-black px-4 py-3 text-sm text-white shadow-md"
      : "font-480 tooltip-content max-w-[300px] select-none break-normal bg-white border-2 border-zettlor-new-black px-4 py-3 text-sm text-zettlor-new-black",
    contentClassName,
  );
  return (
    <>
      <span className={classNames("hidden md:inline-block", wrapperClassName)}>
        <TooltipPrimitive.Provider delayDuration={50}>
          <TooltipPrimitive.Root>
            <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
            {content && (
              <TooltipPrimitive.Portal>
                <TooltipPrimitive.Content className={contentClassNames} sideOffset={5} {...props}>
                  {content}
                  {variant === "dark" && <TooltipPrimitive.Arrow className="fill-zettlor-new-black" />}
                </TooltipPrimitive.Content>
              </TooltipPrimitive.Portal>
            )}
          </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
      </span>
      <span className={classNames("inline-block md:hidden", wrapperClassName)}>
        <PopoverPrimitive.Root>
          <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
          {content && (
            <PopoverPrimitive.Portal>
              <PopoverPrimitive.Content className={contentClassNames} sideOffset={5} {...props}>
                {content}
                <PopoverPrimitive.Close className="absolute right-[5px] top-[5px] outline-none" aria-label="Close">
                  <XMarkIcon className="h-5 w-5" />
                </PopoverPrimitive.Close>
                {variant === "dark" && <PopoverPrimitive.Arrow className="fill-zettlor-new-black" />}
              </PopoverPrimitive.Content>
            </PopoverPrimitive.Portal>
          )}
        </PopoverPrimitive.Root>
      </span>
    </>
  );
};

export default Tooltip;
