import classNames from "classnames";
import { FC, ReactNode } from "react";

interface NoticeProps {
  variant: "error" | "info" | "success" | "warning";
  children: string | ReactNode;
  className?: string;
}

const noticeTypeMap = {
  error: "bg-red-500 text-white",
  success: "bg-zettlor-green-500 text-white",
  warning: "bg-amber-200",
  info: "bg-zettlor-new-black/[0.08]",
};

export const Notice: FC<NoticeProps> = ({ variant, children, className }) => (
  <>
    {children && (
      <div
        className={classNames(
          "px-4 py-3 flex items-start space-x-1.5 rounded-lg font-500",
          noticeTypeMap[variant],
          className,
        )}
      >
        {children}
      </div>
    )}
  </>
);
