import { z } from "zod";

export const zodSelectOption = z.object(
  {
    label: z.string(),
    value: z.string(),
  },
  {
    errorMap: (_i, _c) => {
      return {
        message: "Select a valid option",
      };
    },
  },
);

export const zodSelectOptionArray = z.array(
  z.object({
    label: z.string(),
    value: z.string(),
  }),
);

export const zodFirstName = z
  .string()
  .min(2, {
    message: "First name must be at least 2 characters",
  })
  .max(100, {
    message: "First name must be less than 100 characters",
  });

export const zodLastName = z
  .string()
  .min(2, {
    message: "Last name must be at least 2 characters",
  })
  .max(100, {
    message: "Last name must be less than 100 characters",
  });
